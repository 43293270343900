import styled from "styled-components";

// 공통 버튼 스타일 정의
const ButtonBase = styled.button.attrs((props) => ({
    // isCollapsed는 DOM에 전달되지 않음
}))`
    width: ${(props) => (props.$isCollapsed ? props.$isLocked ? "62" : "40px" : "196px")};
    height:  ${(props) => (props.$isCollapsed ? "32px" : "37px")};
    padding: 8px 12px;
    //justify-content: ${(props) => (props.$isCollapsed ? "center" : "flex-start")};
    justify-content: flex-start;
    align-items: center;
    gap: 12px;
    border: none;
    display: inline-flex;
    background: #ffffff;
    color: #5a5a5a;
    font-size: 14px;
    font-weight: 700;
    border-radius: 8px;
    cursor: pointer;

    &:hover {
        background-color: ${(props) => (props.$isCollapsed ? "none" : "#f5f5f5")};
    }

    span {
        display: ${(props) => (props.$isCollapsed ? "none" : "inline")};
    }
`;

// 네비게이션 컨테이너 스타일
export const Nav = styled.div.attrs((props) => ({
    // DOM 전달 방지
}))`
    display: flex;
    flex-direction: column;
    max-width: ${(props) => (props.$isCollapsed ? "62px" : "196px")};
    width: 100%;
    padding: 16px 0;
    transition: max-width 0.3s ease-in-out;
    gap: 8px;
`;

// 네비게이션 버튼 (기본 버튼 스타일 사용)
export const NavButton = styled(ButtonBase)``;

export const NavButtonActive = styled(ButtonBase)`
    background: #f5f5f5;
    color: black;
`;

// 서브 네비게이션 컨테이너
export const SubNav = styled.div.attrs((props) => ({
    // DOM 전달 방지
}))`
    display: ${(props) => (props.$isCollapsed ? "none" : "flex")};
    flex-direction: column;
    width: 100%;
    gap: 8px;
`;

// 서브 네비게이션 버튼
export const SubNavButton = styled(ButtonBase)`
    padding: ${(props) => (props.$isCollapsed ? "8px" : "15px 45px")};
    font-weight: 400;
`;

export const SubNavButtonActive = styled(SubNavButton)`
    background: #f5f5f5;
    color: black;
`;

// 토글 버튼 스타일
export const ToggleButton = styled.div.attrs((props) => ({
    // DOM 전달 방지
}))`
    display: flex;
    align-items: center;
    justify-content: ${(props) => (props.$isCollapsed ? "flex-start" : "flex-end")};
    width: 100%;
    padding: 16px 0;
    cursor: pointer;
    font-size: 14px;
    transition: all 0.3s ease-in-out;

    span {
        display: ${(props) => (props.$isCollapsed ? "none" : "inline")};
        margin-left: ${(props) => (props.$isCollapsed ? "0px" : "8px")};
    }

    img {
        transform: ${(props) => (props.$isCollapsed ? "rotate(180deg)" : "rotate(0deg)")};
    }
`;
