import {dummycall} from "../utils/dummy";
import axiosInstance from "../utils/axiosConfig";
const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

// 단계별 진행 상태 업데이트
export const handleSubmit = async (e, {
    file, youtubeUrl, setIsLoading, setProgress, setResult, projectId, accessToken
}) => {
    e.preventDefault();

    if (!file && !youtubeUrl) {
        alert('Please select a file or enter a YouTube URL.');
        return;
    }

    const formData = new FormData();
    const url = `${BACKEND_URL}/flow/${projectId}`;

    if (file) {
        formData.append('file', file);
        formData.append('inputType', 'file');
    } else if (youtubeUrl) {
        formData.append('input', youtubeUrl);
        formData.append('inputType', 'url');
    }

    // formData.append('media_type', 'audio');
    // formData.append('num_speakers', num_speakers);

    setIsLoading(true);
    setProgress({step: 'upload', percent: 0});

    try {
        // dummy call
        await dummycall();

        // 첫 번째 요청 (파일 업로드 및 자막 데이터 받기)
        const response = await axiosInstance.post(url, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
            onUploadProgress: progressEvent => {
                const uploadProgress = Math.round((progressEvent.loaded * 25) / progressEvent.total);
                setProgress({step: 'upload', percent: uploadProgress});
            },
            onDownloadProgress: progressEvent => {
                const downloadProgress = Math.round(25 + (progressEvent.loaded * 25) / progressEvent.total);
                setProgress(prev => ({
                    step: 'download',
                    percent: Math.max(prev.percent, downloadProgress)
                }));
            },
        });

        if(response.status === 401) {

        }

        const data = response.data.content;
        setResult(data);

        setProgress({step: 'complete', percent: 100});

    } catch (error) {
        console.error('Error processing request:', error);
        setProgress({step: 'error', percent: 0});
    } finally {
        // setIsLoading(false);
    }
};
