import React, {useEffect, useState} from 'react';
import {Form} from 'reactstrap';
import {useLocation, useNavigate} from 'react-router-dom';
import {handleSubmit} from "../../apis/product/pipeline";
import {
    Content,
    MainContainer,
    MainContent,
    EmptySpace,
    Title
} from "../../styles/styles";
import Menubar from "../../components/menubar/MenuBar";
import Nav from "../../components/sidebar/Nav";
import {
    FileButtonStyle,
    FileUploadContainer,
    FileUploadContent,
    FileUploadTitle,
    FlowWrapper,
    FlowContent,
    FlowTitle,
    DropzoneContainer,
    Message,
    FlowContainer,
    Divider,
    FlowData,
    CreatedDate, FlowDataContainer
} from "./FileUploadStyles";
import ProgressBar from "../../components/ProgressBar";
import {fetchVideoInfo} from "../../apis/youtube/youtubeData";
import {useSelector} from "react-redux";
import {parseFullText} from "../../utils/parse";  // Import the fetchVideoInfo function
import edit from "../../assets/upload/upload_edit.svg";
import file_icon from "../../assets/upload/upload_file.svg";
import subtitle from "../../assets/newflow/newflow_subtitle.svg";
import separate from "../../assets/newflow/newflow_separate.svg";
import dubbing from "../../assets/newflow/newflow_dubbing.svg";

import SpeakerOption from "../../components/upload/options/speaker/SpeakerOption";
import InstrumentOption from "../../components/upload/options/instrument/InstrumentOption";

const INSTRUMENTS = {
    '보컬': 'vocals',
    '드럼': 'drums',
    '베이스': 'bass',
    '피아노': 'piano',
    '그외': 'other',
};

const FileUploadPage = () => {
    const productTexts = {
        'source-separation': '음원분리',
        'audio-dubbing': '오디오 더빙',
    };

    const productImages = {
        'subtitle': subtitle,
        'source-separation': separate,
        'audio-dubbing': dubbing,
    }

    const location = useLocation();    // useLocation을 사용하여 state 값을 가져옴
    const {product} = location.state;    // loacation.state에서 product를 가져옴
    const {title} = location.state;    // location.state에서 title을 가져옴

    // Redux 상태에서 projectId 가져오기
    const projectId = useSelector((state) => state.product.projectId);
    const flowName = useSelector((state) => state.product.flowName);

    const [file, setFile] = useState(null);
    const [youtubeUrl, setYoutubeUrl] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    let [result, setResult] = useState([]);
    const [progress, setProgress] = useState(0);
    const [videoTitle, setVideoTitle] = useState('');
    const [thumbnailUrl, setThumbnailUrl] = useState('');
    const [num_speakers, setNumSpeakers] = useState(0);  // Default to 0 (auto)
    const [selectedInstruments, setSelectedInstruments] = useState([]);

    const {step, percent} = progress;
    const navigate = useNavigate();

    useEffect(() => {
        console.log('step:', step, 'percent:', percent);
        if (percent === 100 && step === 'complete') {
            console.log('progress:', progress);
            // 먼저 Source Separation을 확인
            if (result !== null && product === 'source-separation' && percent === 100) {
                const timer = setTimeout(() => {
                    alert('음원 분리가 완료되었습니다.');
                    console.log('result:', result.output);
                    navigate('/source-separation', {
                        state: {
                            result,
                            selectedInstruments
                        }
                    });
                }, 1000);
                return () => clearTimeout(timer);
            } else if (result !== null && product === 'subtitle' && percent === 100) {
                const timer = setTimeout(() => {
                    console.log('result:', result);
                    alert('자막 생성이 완료되었습니다.');
                    result = parseFullText(result);
                    navigate('/subtitle-generation', {
                        state: {
                            result,
                        }
                    });
                }, 1000);
                return () => clearTimeout(timer);
            }
        } else if (percent === 0 && step === 'error') {
            alert('오류가 발생했습니다 다시 시도해주세요.');
        }
    }, [step, navigate, result]);

    const handleFileSelect = (e) => {
        const selectedFile = e.target.files[0]; // 파일 선택기에서 선택된 파일
        if (selectedFile) {
            setFile(selectedFile); // 선택된 파일을 상태로 설정
        }
        setYoutubeUrl(selectedFile ? selectedFile.name : "");
    };

    const handleDrop = (e) => {
        e.preventDefault();
        const droppedFile = e.dataTransfer.files[0]; // 드래그된 파일
        if (droppedFile) {
            setFile(droppedFile); // 드래그된 파일을 상태로 설정
        }
        setYoutubeUrl(droppedFile ? droppedFile.name : "");
    };

    const handleDragOver = (e) => {
        e.preventDefault(); // 드래그가 올바르게 작동하도록 preventDefault() 호출
    };

    const handleUrlChange = (e) => {
        setYoutubeUrl(e.target.value);
    };

    // Handle num_speakers increase
    const handleSpeakerIncrease = () => {
        setNumSpeakers((prevSpeakers) => prevSpeakers + 1);
    };

    // Handle num_speakers decrease
    const handleSpeakerDecrease = () => {
        setNumSpeakers((prevSpeakers) => (prevSpeakers > 0 ? prevSpeakers - 1 : 0));
    };

    // Handle instrument selection
    const handleInstrumentChange = (instrument) => {
        setSelectedInstruments(prevInstruments =>
            prevInstruments.includes(instrument)
                ? prevInstruments.filter(i => i !== instrument)
                : [...prevInstruments, instrument]
        );
    };

    const handleFormSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);

        try {
            if (file === null && youtubeUrl === "") {
                alert("동영상 URL을 입력하거나 파일을 업로드해주세요.");
                setIsLoading(false);
                return;
            }

            if (file === null && youtubeUrl !== "") {
                await fetchVideoInfo(youtubeUrl, setVideoTitle, setThumbnailUrl);
            } else {
                setVideoTitle(file.name);
                setThumbnailUrl(null);
            }

            const isSourceSeparation = product === 'source-separation';
            const isSubtitle = product === 'subtitle';

            if (isSourceSeparation && selectedInstruments.length === 0) {
                alert("악기를 선택해주세요.");
                setIsLoading(false);
                return;
            }

            const additionalData = isSourceSeparation
                ? {instrumentsString: selectedInstruments.join(',')}
                : isSubtitle
                    ? {num_speakers}
                    : {};

            await handleSubmit(e, {
                file,
                youtubeUrl,
                setIsLoading,
                setResult,
                setProgress,
                projectId,
                ...additionalData
            });

        } catch (error) {
            console.error("Error during submission:", error);
        }
    };

    return (
        <MainContainer>
            <Menubar/>
            <Content>
                <Nav/>
                <MainContent>
                    <FileUploadContainer>
                        <Title>{title}</Title>
                        <FlowWrapper>
                            <FlowContainer>
                                <FlowTitle>
                                    <span>{flowName}</span>
                                    <img src={edit} alt="edit"/>
                                </FlowTitle>
                                <FlowContent>
                                    <img src={productImages[product]} alt={product}/>
                                    <span>{productTexts[product] || '자막생성'}</span>
                                </FlowContent>
                                <Divider/>
                                <FlowDataContainer>
                                    <FlowData>
                                        생성일시
                                        <span>2025-01-01</span>
                                    </FlowData>
                                    <FlowData>
                                        가격
                                        <span>0원</span>
                                    </FlowData>
                                </FlowDataContainer>
                            </FlowContainer>
                        </FlowWrapper>
                        <FileUploadContent>
                            <FileUploadTitle>파일 업로드</FileUploadTitle>
                            {product === 'subtitle' ? <SpeakerOption
                                num_speakers={num_speakers}
                                handleSpeakerDecrease={handleSpeakerDecrease}
                                handleSpeakerIncrease={handleSpeakerIncrease}
                            /> : <InstrumentOption
                                INSTRUMENTS={INSTRUMENTS}
                                handleInstrumentChange={handleInstrumentChange}
                            />}
                            <Form className='form' onSubmit={handleFormSubmit}>
                                <div className='url'>
                                    <input
                                        type="text"
                                        placeholder="유튜브 URL을 입력하세요"
                                        value={youtubeUrl}
                                        onChange={handleUrlChange}
                                    />
                                    <FileButtonStyle type="submit" disabled={isLoading}>
                                        생성하기
                                    </FileButtonStyle>
                                </div>
                                <div className="finder">
                                    혹은 <span>내 PC에서 선택</span>
                                </div>
                                <DropzoneContainer
                                    onDrop={handleDrop}
                                    onDragOver={handleDragOver}
                                >
                                    <input
                                        type="file"
                                        id="file-upload"
                                        accept=".mp4, .avi, .mov, mp3, .wav, .flac, .aac"
                                        style={{display: 'none'}} // 숨겨진 파일 선택기
                                        onChange={handleFileSelect}
                                    />
                                    <img src={file_icon} alt="file"/>
                                    <Message>
                                        <button
                                            type="button"
                                            onClick={() => document.getElementById('file-upload').click()}
                                        >
                                            파일을 선택
                                        </button>
                                        <span>
                                            하거나 여기에 드래그하세요
                                        </span>
                                    </Message>
                                </DropzoneContainer>
                                {isLoading && (
                                    <ProgressBar progress={progress} thumbnailUrl={thumbnailUrl}
                                                 videoTitle={videoTitle}/>
                                )}
                            </Form>
                        </FileUploadContent>
                        <EmptySpace/>
                    </FileUploadContainer>
                </MainContent>
            </Content>
        </MainContainer>
    );
};

export default FileUploadPage;
