// index/authSlice.js
import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import axios from "axios";


const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

export const logout = createAsyncThunk('auth/logout', async (_, { dispatch, getState }) => {
    try {
        // getState()를 사용하여 redux에서 accessToken을 가져옴
        const { accessToken } = getState().auth;

        // 로그아웃 API 호출 시 accessToken을 Authorization 헤더에 추가
        await axios.post(`${BACKEND_URL}/logout`, {}, {
            headers: {
                'Authorization': `Bearer ${accessToken}`,
            },
            withCredentials: true, // 쿠키와 같은 자격 증명을 포함
        });

        // 로그아웃 성공 후 accessToken 제거
        dispatch(clearAccessToken());
    } catch (error) {
        console.error('Logout failed:', error);
        throw new Error('Logout failed');
    }
});

const authSlice = createSlice({
    name: 'auth',
    initialState: {
        accessToken: null,  // localStorage에서 초기화 (옵션)
    },
    reducers: {
        setAccessToken(state, action) {
            state.accessToken = action.payload;
        },
        clearAccessToken(state) {
            state.accessToken = null;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(logout.fulfilled, (state) => {
            state.accessToken = null;  // 로그아웃 성공 시 액세스 토큰 제거
        });
    }
});

export const { setAccessToken, clearAccessToken } = authSlice.actions;

export default authSlice.reducer;
