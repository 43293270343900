import styled from 'styled-components';

export const Container = styled.div`
    width: 100%;
    background: linear-gradient(
            238deg,
            rgba(48, 176, 199, 0.16) 0%,
            rgba(255, 255, 255, 0.20) 100%
    );
`;

export const HeroSection = styled.div`
    display: flex;
    flex-direction: row; /* 좌우로 배치 */
    width: 100%;
    height: 900px; /* 고정 높이 */
    max-width: 1440px;
    margin: 0 auto; /* 중앙 정렬 */
    
    @media (max-width: 1280px) {
        flex-direction: column; /* 작은 화면에서는 상하로 변경 */
        height: 1350px; /* 고정 높이 */
    }
`;

export const HeroTitle = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: 50%; /* 화면 너비의 절반 */
    height: 100%; /* 부모 높이를 차지 */
    padding: 100px;
    box-sizing: border-box;

    h1 {
        font-size: 50px;
        font-weight: 600;
        margin-bottom: 20px;
        word-wrap: break-word;
    }

    p {
        font-size: 20px;
        font-weight: 500;
        margin-bottom: 40px;
        color: black;
        word-wrap: break-word;
    }

    button {
        width: 250px;
        height: 82px;
        padding: 10px;
        border-radius: 16px;
        border: none;
        background-color: #30B0C7;
        color: white;
        font-size: 28px;
        font-weight: 600;
        cursor: pointer;
        transition: background-color 0.3s;

        &:hover {
            background-color: rgba(48, 176, 199, 0.8);
        }
    }

    @media (max-width: 1280px) {
        width: 100%; /* 화면 너비의 절반 */
        height: auto;
        padding: 150px 50px; /* 상하 150px, 좌우 50px */
        box-sizing: border-box;
        text-align: center;
        align-items: center;
    }
`;

export const HeroImage = styled.div`
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    width: 50%; /* 화면 너비의 절반 */
    height: 100%; /* 부모 높이를 차지 */
    position: relative; /* 자식들의 position: absolute 기준이 됨 */
    padding-left: 50px; /* 텍스트와 이미지 사이 간격 */

    img {
        position: absolute;
        object-fit: cover;
        opacity: 1; /* 기본 상태 */

        &:nth-child(1) {
            z-index: 2;
            right: 90px; /* 약간 왼쪽으로 이동 */
            transition: transform 1s ease, opacity 1s ease; /* 애니메이션 효과 */
            max-width: 676px;
        }

        &:nth-child(2) {
            z-index: 1;
            right: 0; /* 오른쪽 정렬 */
            transition: transform 1s ease, opacity 1s ease; /* 애니메이션 효과 */
            max-height: 827px;
        }

        &.hidden {
            opacity: 0; /* 투명 */
            transform: translateY(50px); /* 아래로 이동 */
        }

        &.visible {
            opacity: 1; /* 보임 */
            transform: translateY(0); /* 제자리 */
        }
    }

    @media (max-width: 1280px) {
        width: 100%; /* 화면 너비를 가득 채움 */
        height: 100%; /* 부모 높이를 자동 조정 */
        img {
            position: absolute; /* absolute를 유지해 겹쳐 보이도록 설정 */
            object-fit: contain; /* 이미지를 비율에 맞게 조정 */
            width: auto;
            height: auto;

            &:nth-child(1) {
                width: auto;
                height: 65%;
                max-width: 90%;
            }

            &:nth-child(2) {
                width: auto;
                right: 0; /* 오른쪽 정렬 */
                height: 100%;
                max-width: 70%;
            }
        }
    }
`;


export const Section = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 577px;
    padding: 150px 72px;
    background-color: #30B0C7;
    z-index: 2;

    h2 {
        font-size: 48px;
        font-weight: 700;
        color: white;
    }

    p {
        font-size: 32px;
        font-weight: 600;
        text-align: center;
        line-height: 52px;
        background: linear-gradient(180deg, rgba(225, 225, 225, 0.61) 0%, #fff 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        color: transparent; /* Fallback */
    }

    span {
        display: block;
    }
`;

export const Section2 = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 916px;
    padding: 150px 72px;
    z-index: 2;
`;

export const ProductTag = styled.div`
    display: inline-flex;
    background-color: rgba(48, 176, 199, 0.07);
    justify-content: center;
    align-items: center;
    color: #30B0C7;
    padding: 9px 36px;
    border-radius: 10px;
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 15px;
`;

export const Title = styled.h1`
    font-size: 48px;
    font-weight: 700;
    margin-bottom: 120px;
    line-height: 64px;
    text-align: center;
`;


export const FeaturesGrid = styled.div`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 70px;
    width: 950px;

    @media (max-width: 768px) {
        grid-template-columns: 1fr;
        gap: 32px;
    }
`;

export const FeatureCard = styled.div`
    display: flex;
    flex-direction: column;
    height: 298px;
    background: white;
    padding: 0 30px;
    border-radius: 12px;
    text-align: center;
    justify-content: center;
    align-items: center;
    transition: transform 0.2s ease;
    gap: 28px;

    &:hover {
        transform: translateY(-5px);
        box-shadow: 0 18px 50px rgba(0, 0, 0, 0.12);
    }
`;

export const FeatureIcon = styled.div`
    height: 51px;
    
    img {
        width: 100%;
        height: 100%;
        object-fit: contain;
    }
`;

export const FeatureContent = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
`;

export const FeatureTitle = styled.h3`
    font-size: 20px;
    font-weight: 700;
    color: black;
    margin-bottom: 28px;
`;

export const FeatureDescription = styled.p`
    font-size: 15px;
    font-weight: 600;
    line-height: 20px;
    color: black;
    margin: 0;
`;

export const LearnMore = styled.a`
    display: inline-flex;
    align-items: center;
    color: #30B0C7;
    text-decoration: none;
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;

    &::after {
        content: '→';
        margin-left: 8px;
    }
`;

export const Button = styled.button`
    width: 250px;
    height: 82px;
    padding: 10px;
    border-radius: 16px;
    border: none;
    background-color: #30B0C7;

    color: white;
    font-size: 28px;
    font-weight: 600;

    cursor: pointer;
    transition: background-color 0.3s;
    word-wrap: break-word;

    &:hover {
        background-color: rgba(48, 176, 199, 0.8);
    }
`;

export const Section3 = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 916px;
    padding: 150px 72px;
    z-index: 2;
    background: linear-gradient(0deg, rgba(48, 176, 199, 0.07) 0%, rgba(48, 176, 199, 0.07) 100%);
`;
