import styled from "styled-components";

export const InstrumentOptionStyles = styled.div`
    margin-top: 20px;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 14px;

    .label {
        font-weight: 500;
        margin-right: 10px;
    }

    div {
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    input[type="checkbox"] {
        margin: 0 10px;
        width: 15px;
    }

    label {
    }
`;
