import styled from "styled-components";

export const ProjectContainer = styled.div`
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    padding: 20px;
    border-radius: 12px;
    background: #F7F8F9;
    border: 1px solid #DEDFE2;
    //box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    transition: 0.3s;
    &:hover {
        transform: scale(1.02);
    }
`;

export const Button = styled.button`
    font-size: 49px;
    font-weight: 700;
    width: 262px;
    height: 130px;
    color: #DEDFE2;
    padding: 10px;
    cursor: pointer;
    border-radius: 12px;
    background: #F7F8F9;
    border: 1px solid #DEDFE2;
`;
