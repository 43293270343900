import React, {useEffect, useState} from "react";
import {useSelector, useDispatch} from "react-redux";
import {useLocation, useNavigate} from "react-router-dom";
import {setActiveButton, setSidebarLocked, toggleSidebar} from "../../redux/slices/navigationSlice"; // 슬라이스 경로에 따라 수정
import {
    Nav,
    NavButton,
    NavButtonActive,
    SubNav,
    SubNavButton,
    SubNavButtonActive,
    ToggleButton
} from "./NavStyles";
import nav from "../../assets/sidebar/nav.svg";
import home from "../../assets/sidebar/sidebar_home.svg";
import newflow from "../../assets/sidebar/sidebar_newflow.svg";
import myflow from "../../assets/sidebar/sidebar_myflow.svg";
import document from "../../assets/sidebar/sidebar_document.svg";
import mypage from "../../assets/sidebar/sidebar_mypage.svg";
import apikey from "../../assets/sidebar/sidebar_apikey.svg";
import reference from "../../assets/sidebar/sidebar_reference.svg";
import {MdOutlineLock} from "react-icons/md";


const Navigation = () => {
    const activeButton = useSelector((state) => state.navigation.activeButton); // 상태 가져오기
    const isCollapsed = useSelector((state) => state.navigation.isCollapsed); // Redux에서 isCollapsed 상태 가져오기
    const isLocked = useSelector((state) => state.navigation.isLocked); // Redux에서 isLocked 상태 가져오기
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();

    // URL과 상태 동기화
    useEffect(() => {
        const currentPath = location.pathname.replace("/", ""); // URL에서 현재 경로 추출
        if (currentPath && currentPath !== activeButton) {
            dispatch(setActiveButton(currentPath)); // Redux 상태 업데이트
        }
    }, [location.pathname, activeButton, dispatch]);

    const handleButtonClick = (buttonId) => {
        dispatch(setActiveButton(buttonId)); // 상태 업데이트
        navigate(`/${buttonId}`);
    };

    const handleToggleClick = () => {
        dispatch(toggleSidebar());
        dispatch(setSidebarLocked());
    }

    return (
        <Nav $isCollapsed={isCollapsed}>
            <NavButton
                onClick={() => handleButtonClick("home")} $isCollapsed={isCollapsed}
                as={activeButton === "home" ? NavButtonActive : NavButton}
            >
                <img src={home} alt={"home"}/>
                <span>홈</span>
            </NavButton>
            <NavButton
                onClick={() => handleButtonClick("newflow")} $isCollapsed={isCollapsed}
                as={activeButton === "newflow" ? NavButtonActive : NavButton}
            >
                <img src={newflow} alt={"newflow"}/>
                <span>새로운 플로우</span>
            </NavButton>
            <NavButton
                onClick={() => handleButtonClick("myflow")} $isCollapsed={isCollapsed}
                as={activeButton === "projmyflow" ? NavButtonActive : NavButton}
            >
                <img src={myflow} alt={"myflow"}/>
                <span>나의 플로우</span>
            </NavButton>
            <NavButton
                onClick={() => handleButtonClick("document")} $isCollapsed={isCollapsed}
                as={activeButton === "document" ? NavButtonActive : NavButton}
            >
                <img src={document} alt={"document"}/>
                <span>도큐먼트</span>
            </NavButton>
            <SubNav $isCollapsed={isCollapsed}>
                <SubNavButton
                    onClick={() => handleButtonClick("running")} $isCollapsed={isCollapsed}
                    as={activeButton === "running" ? SubNavButtonActive : SubNavButton}
                >
                    <span>처리 중인 파일</span>
                </SubNavButton>
                <SubNavButton
                    onClick={() => handleButtonClick("files")} $isCollapsed={isCollapsed}
                    as={activeButton === "files" ? SubNavButtonActive : SubNavButton}
                >
                    <span>모든 파일</span>
                </SubNavButton>
            </SubNav>
            <NavButton
                // onClick={() => handleButtonClick("mypage")}
                $isCollapsed={isCollapsed}
                $isLocked={isLocked}
                // as={activeButton === "mypage" ? NavButtonActive : NavButton}
            >
                <img src={mypage} alt={"mypage"}/>
                <span>마이페이지</span>
                <MdOutlineLock style={{color: 'DEE2E6', width: '10px', height: '13px'}}/>
            </NavButton>
            <SubNav $isCollapsed={isCollapsed}>
            <SubNavButton
                    // onClick={() => handleButtonClick("usage")}
                    $isCollapsed={isCollapsed}
                    // as={activeButton === "usage" ? SubNavButtonActive : SubNavButton}
                >
                    <span>사용량</span>
                </SubNavButton>
                <SubNavButton
                    // onClick={() => handleButtonClick("billing")}
                    $isCollapsed={isCollapsed}
                    // as={activeButton === "billing" ? SubNavButtonActive : SubNavButton}
                >
                    <span>결제정보</span>
                </SubNavButton>
            </SubNav>
            {/* 토글 버튼 */}
            <ToggleButton $isCollapsed={isCollapsed}>
                <img onClick={handleToggleClick} src={nav} alt="nav"/>
            </ToggleButton>
            <div style={{height: '140px'}}/>
            <NavButton
                // onClick={() => handleButtonClick("apikey")}
                $isCollapsed={isCollapsed}
                $isLocked={isLocked}
                // as={activeButton === "apikey" ? NavButtonActive : NavButton}
            >
                <img src={apikey} alt={"apikey"}/>
                <span>API Key</span>
                <MdOutlineLock style={{ color: 'DEE2E6', width: '10px', height: '13px'}}/>
            </NavButton>
            <NavButton
                // onClick={() => handleButtonClick("reference")}
                $isCollapsed={isCollapsed}
                $isLocked={isLocked}
                // as={activeButton === "reference" ? NavButtonActive : NavButton}
            >
                <img src={reference} alt={"reference"}/>
                <span>레퍼런스</span>
                <MdOutlineLock style={{ color: 'DEE2E6'}} size={13}/>
            </NavButton>
        </Nav>
    );
};

export default Navigation;
