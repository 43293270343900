import menu_logo from "../../assets/menu/logo.svg";
import {
    CenterSection,
    ContactButton,
    LoginButton,
    Logo, LogoSection,
    MenuItem,
    MenuLink,
    MenuList,
    NavContainer,
    NavInner, RightSection, SignUpButton
} from "./MenubarStyles";
import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {MdOutlineLock} from "react-icons/md";
import {useDispatch, useSelector} from "react-redux";
import {logout} from "../../redux/slices/authSlice";
import useAlert from "../../hooks/useAlert";
import AlertModal from "../alert/AlertModal";
import myprofile from "../../assets/menu/menu_myprofile.svg";

const Menubar = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const {isAlertVisible, title, message, showAlert, hideAlert} = useAlert();

    // Redux에서 accessToken을 가져옴
    const accessToken = useSelector((state) => state.auth.accessToken);

    const [activeMenu, setActiveMenu] = useState('제품');
    const menuItems = ['프로덕트', '솔루션', '요금제', '리소스', '워크스페이스'];

    const handleNavigate = (path) => {
        if (path === '/workspace' && !accessToken) {
            alert('This page requires login.');
            return;
        }
        navigate(path);
    }

    // 로그아웃 처리
    const handleLogout = async () => {
        try {
            // 로그아웃 비동기 액션을 dispatch
            const actionResult = await dispatch(logout());

            if (logout.fulfilled.match(actionResult)) {
                showAlert('로그아웃 되었습니다.', '시작 페이지로 이동합니다.', '/'); // 로그아웃 성공 시 알림
            } else {
                window.location.href = '/login';
            }
        } catch (error) {
            showAlert('로그아웃 실패', '로그아웃 중 오류가 발생했습니다.');
            console.error(error);
        }
    };

    // 로그인 상태 변경은 accessToken 상태가 변경될 때마다 자동으로 처리
    useEffect(() => {
        // 로그아웃 시 자동으로 navigate가 호출되므로 별도의 상태 관리 없이 바로 접근
    }, [accessToken]); // accessToken 상태가 변경될 때마다 실행

    return (
        <NavContainer style={{pointerEvents: isAlertVisible ? 'none' : 'auto'}}>
            {isAlertVisible && <AlertModal title={title} message={message} onClose={hideAlert}/>}
            <NavInner>
                <LogoSection>
                    <Logo src={menu_logo} alt="logo" onClick={() => {
                        handleNavigate("/"); // 기존 내비게이션 로직 유지
                        setActiveMenu(null); // active 상태 초기화
                    }}/>
                </LogoSection>
                <CenterSection>
                    <MenuList>
                        {menuItems.map((item) => (
                            <MenuItem key={item} disableHover={item === '프로덕트' || item === '요금제'}>
                                <MenuLink
                                    href="#"
                                    active={activeMenu === item && item !== '프로덕트' && item !== '요금제'}
                                    disableHover={item === '프로덕트' || item === '요금제'}
                                    onClick={() => {
                                        if (item !== '프로덕트' && item !== '요금제') {
                                            setActiveMenu(item);
                                        }
                                    }}
                                >
                                    {item}
                                    {(item === '프로덕트' || item === '요금제') &&
                                        <MdOutlineLock style={{marginLeft: '4px'}} size={13}/>}
                                </MenuLink>
                            </MenuItem>
                        ))}
                    </MenuList>
                </CenterSection>
                <RightSection>
                    {accessToken ? (
                        <img src={myprofile} onClick={() => handleNavigate("/myprofile")}/> // 로그인 상태일 때 Workspace 버튼 표시
                    ) : (
                        <SignUpButton onClick={() => handleNavigate("/signup")}>회원가입</SignUpButton> // 로그아웃 상태일 때 Signup 버튼 표시
                    )
                    }
                    <LoginButton onClick={accessToken ? handleLogout : () => handleNavigate("/login")}>
                        {accessToken ? "로그아웃" : "로그인"}
                    </LoginButton>
                    <ContactButton>문의하기</ContactButton>
                </RightSection>
            </NavInner>
        </NavContainer>
    )
}


export default Menubar;
