import React, {useState} from "react";
import {Link, useNavigate} from "react-router-dom";
import logo from "../../assets/signup/signup_logo.svg";
import google from "../../assets/login_google.svg";
import git from "../../assets/login_git.svg";
import facebook from "../../assets/login_facebook.svg";
import {useDispatch} from "react-redux";
import {
    FormContainer,
    LoginButtonStyle,
    LoginContainer,
    LoginInputLabelStyle,
    LoginInputTextStyle,
    Wrapper,
    PageContainer,
    Title,
    Logo,
    Divider,
    SocialLoginContainer,
    SocialLoginButton,
    SocialLoginIcon,
    Text,
    TermsText, TermsLink
} from "./LoginStyles";
import {loginUser} from "../../redux/actions/authActions";
import useAlert from "../../hooks/useAlert";
import AlertModal from "../../components/alert/AlertModal";
import {FaLock} from "react-icons/fa";
import {MdOutlineLock} from "react-icons/md";

const LoginPage = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const navigate = useNavigate();

    const dispatch = useDispatch();
    const { isAlertVisible, title, message, showAlert, hideAlert } = useAlert();

    // 이메일 형식 확인
    const isValidEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };

    // 버튼 활성화 여부 확인
    const isFormValid = email && password && isValidEmail(email);

    const handleSubmit = async (e) => {
        e.preventDefault();

        const user = { email, password };

        // Redux를 통한 로그인 처리
        const result = await dispatch(loginUser(user));

        if (result.success) {
            // window.location.href = '/home';
            navigate('/home');
            setEmail('');
            setPassword('');
        } else {
            showAlert('로그인 실패', '올바른 이메일 혹은 비밀번호를 입력하세요.');
        }
    };

    return (
        <PageContainer style={{ pointerEvents: isAlertVisible ? 'none' : 'auto' }}>
            {isAlertVisible && <AlertModal title={title} message={message} onClose={hideAlert} />}
            <LoginContainer>
                <Logo>
                    <img src={logo} alt="logo" />
                </Logo>
                <Title>오디온 로그인</Title>
                <SocialLoginContainer>
                    <SocialLoginButton>
                        <SocialLoginIcon src={google} alt="google" />
                        <span>구글로 시작하기</span>
                        <MdOutlineLock/>
                    </SocialLoginButton>
                    <SocialLoginButton>
                        <SocialLoginIcon src={git} alt="git" />
                        <span>Github로 시작하기</span>
                        <MdOutlineLock/>
                    </SocialLoginButton>
                    <SocialLoginButton>
                        <SocialLoginIcon src={facebook} alt="facebook" />
                        <span>페이스북으로 시작하기</span>
                        <MdOutlineLock/>
                    </SocialLoginButton>
                </SocialLoginContainer>
                <Divider>
                    <p>또는</p>
                </Divider>
                <FormContainer
                    onSubmit={(e) => {
                        if (isAlertVisible) {
                            e.preventDefault();
                        } else {
                            handleSubmit(e);
                        }
                    }}
                >
                    <LoginInputLabelStyle>이메일</LoginInputLabelStyle>
                    <LoginInputTextStyle
                        type="text"
                        placeholder="이메일을 입력하세요"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                    />
                    <LoginInputLabelStyle>비밀번호</LoginInputLabelStyle>
                    <LoginInputTextStyle
                        type="password"
                        placeholder="비밀번호를 입력하세요"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                    />
                    <LoginButtonStyle type="submit" disabled={!isFormValid}>
                        로그인
                    </LoginButtonStyle>
                </FormContainer>
                <Wrapper>
                    <Text as={Link} to="/signup">회원가입</Text> {/* 링크로 이동하도록 설정 */}
                    <Text color="#E7E7E7" fontWeight="500">|</Text>
                    <Text as={Link} to="/forgot-password">비밀번호 찾기</Text> {/* 링크로 이동하도록 설정 */}
                </Wrapper>
                <TermsText>
                    계속하시면 오디온의 <TermsLink onClick={() => window.location.href = '/terms'}>이용약관</TermsLink> 및<br />
                    <TermsLink
                        onClick={() => window.location.href = '/privacy'}>개인정보처리방침</TermsLink>에 동의하게 됩니다.
                </TermsText>
            </LoginContainer>
        </PageContainer>
    );
};

export default LoginPage;
