import axios from 'axios';
import {queryString} from '../../utils/queryString';
import axiosInstance from "../utils/axiosConfig";

/*

 */

export const getList = async () => {

    const BACKEND_URL = process.env.REACT_APP_DASHBOARD_URL;

    const list = {
        // size: '1'
    }

    try {
        const response = await axiosInstance.get(`${BACKEND_URL}/user/file-list?${queryString(list)}`);

        console.log(response.data);

        return response.data;
    } catch (error) {
        console.error(error);
    }
}
