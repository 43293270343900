import styled, {keyframes} from "styled-components";

const slideDown = keyframes`
    0% {
        transform: translateY(-100%);
    }
    100% {
        transform: translateY(0);
    }
`;

const slideUp = keyframes`
    0% {
        transform: translateY(0);
    }
    100% {
        transform: translateY(-100%);
    }
`;

export const ModalBackground = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(97, 97, 97, 0.45);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999;
`;


export const ModalOverlay = styled.div`
    position: fixed;
    top: 40px;
    left: 0;
    right: 0;
    bottom: 0;
    background: transparent;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    z-index: 999;
    animation: ${slideDown} 0.1s ease-out forwards; /* 열리는 애니메이션 */
`;

export const ModalContent = styled.div`
    width: 450px;
    height: 165px;
    padding: 30px;
    background: white;
    border-radius: 5px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    text-align: center;
    animation: ${slideDown} 0.5s ease-out forwards; /* 기본 열리는 애니메이션 */
    
    h3 {
        font-size: 20px;
        font-weight: 700;
    }
    
    p {
        color: #676F82; 
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
    }

    &.close {
        animation: ${slideUp} 0.5s ease-in forwards; /* 닫힐 때 애니메이션 */
    }
`;

export const CloseButton = styled.button`
    color: #30B0C7;
    background: white;
    border: 1px #30B0C7 solid;
    padding: 5px 10px;
    cursor: pointer;
    border-radius: 5px;

    &:hover {
        background: #30B0C7;
        color: white;
    }
`;
