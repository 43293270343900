import axios from 'axios';
import store from "../../redux";

/*

 */

export const getVolume = async () => {

    const BACKEND_URL = process.env.REACT_APP_DASHBOARD_URL;
    const state = store.getState();
    const accessToken = state.auth.accessToken;
    try{
        const response = await axios.get(`${BACKEND_URL}/user/summary`, {
            headers: {
                Authorization: `Bearer ${accessToken}`,
            },
            withCredentials: false
        });

        console.log(response.data);

        return response.data;
    } catch (error) {
        console.error(error);
    }
}
