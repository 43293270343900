import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import {createProjectName} from "../../apis/utils/uuid";
import axiosInstance from "../../apis/utils/axiosConfig";

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

// API 호출 Thunk
export const fetchProjectData = createAsyncThunk(
    'product/fetchProjectData',
    async (productId, { rejectWithValue }) => {
        try {
            console.log('productId', productId);

            const response = await axiosInstance.post(
                `${BACKEND_URL}/project/${productId}`,
                {
                    headers: {
                        Accept: 'application/json',
                    },
                    withCredentials: false
                }
            );

            console.log('response', response);
            return response.data.content; // content 객체 반환
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);

const productSlice = createSlice({
    name: 'product',
    initialState: {
        projectId: null, // 프로젝트 ID 저장
        productId: null, // 입력된 제품 ID 저장
        flowName: 'Untitled', // 프로젝트 이름 저장
        status: 'idle',  // 상태 관리
        error: null,     // 에러 메시지 저장
    },
    reducers: {
        setProductId: (state, action) => {
            state.productId = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchProjectData.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchProjectData.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.projectId = action.payload.projectId; // projectId 추출
            })
            .addCase(fetchProjectData.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload;
            });
    },
});

export const { setProductId } = productSlice.actions;

export default productSlice.reducer;
