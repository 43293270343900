import {useNavigate} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import React, {useEffect} from "react";
import {fetchProjects} from "../../redux/slices/projectsSlice";
import {ProjectContainer} from "../home/HomePageStyles";
import MenuBar from "../../components/menubar/MenuBar";
import {Content, MainContainer, MainContent, Title} from "../../styles/styles";
import Navigation from "../../components/sidebar/Nav";

const MyFlowPage = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    // Redux에서 프로젝트 상태를 가져옴
    const projects = useSelector((state) => state.projects.items);
    const status = useSelector((state) => state.projects.status);

    // 컴포넌트 마운트 시 API 호출
    useEffect(() => {
        console.log('fetchProjects');
        dispatch(fetchProjects());
    }, [dispatch]);


    return (
        <MainContainer>
            <MenuBar/>
            <Content>
                <Navigation/>
                <MainContent>
                        <Title>나의 플로우</Title>
                        {status === 'loading' && <p>로딩 중...</p>}
                        {projects.map((project, index) => (
                            <ProjectContainer key={index}>
                                <div>프로젝트 이름: {project.name}</div>
                                {project.components.map((component, index) => (
                                    <div key={index}>컴포넌트 이름: {component}</div>
                                ))}
                                <div>생성일시: {project.createdAt}</div>
                                <div>작업한 파일 수: {project.numDocuments}</div>
                            </ProjectContainer>
                        ))}
                </MainContent>
            </Content>
        </MainContainer>
    )
}

export default MyFlowPage;
