// SpeakerOptionStyles.js
import React from 'react';
import {SpeakerOptionStyles} from "./SpeakerOptionStyles";

const SpeakerOption = ({ num_speakers, handleSpeakerDecrease, handleSpeakerIncrease }) => (
    <SpeakerOptionStyles>
        <div className="label">화자 수</div>
        <div>
            <button className="btn" type="button" onClick={handleSpeakerDecrease}>-</button>
            <span>{num_speakers === 0 ? '자동' : num_speakers}</span>
            <button className="btn" type="button" onClick={handleSpeakerIncrease} style={{ marginLeft: '10px' }}>+</button>
        </div>
    </SpeakerOptionStyles>
);

export default SpeakerOption;
