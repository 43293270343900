// redux/index.js
import { configureStore } from "@reduxjs/toolkit";
import listReducer from "./slices/listSlice";
import navigationReducer from "./slices/navigationSlice";
import projectsReducer from "./slices/projectsSlice";
import productReducer from "./slices/productSlice";
import uploadReducer from "./slices/uploadSlice";
import authReducer from "./slices/authSlice";
import alertReducer from "./slices/alertSlice";

const index = configureStore({
    reducer: {
        list: listReducer,         // 기존 리스트 슬라이스
        navigation: navigationReducer, // 새로운 네비게이션 슬라이스 추가
        projects: projectsReducer, // 새로운 프로젝트 슬라이스 추가
        product: productReducer, // 새로운 프로덕트 슬라이스 추가
        upload: uploadReducer, // 새로운 업로드 슬라이스 추가
        auth: authReducer, // 새로운 인증 슬라이스 추가
        alert: alertReducer, // 새로운 알림 슬라이스 추가
    },
});

export default index;
