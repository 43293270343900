import styled from 'styled-components';

export const PageContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: radial-gradient(circle, rgba(148, 177, 200, 0.40) 0%, rgba(48, 176, 199, 0.31) 27%, rgba(148, 177, 200, 0.21) 59%, rgba(255, 255, 255, 0) 100%);
    width: 100%;
    height: 100vh;
    padding: 50px 0;
`;

export const LoginContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 520px;
    height: 913px;
    background: white;
    padding: 36px;
    border-radius: 12px;
`;

export const Logo = styled.div`
    width: 100%;
    margin-bottom: 18px;
    display: flex;
    justify-content: center;

    img {
        width: 38px;
    }
`;

export const Title = styled.div`
    font-size: 32px;
    font-weight: 600;
    line-height: 44px;
    margin-bottom: 40px;
`;

export const SocialLoginContainer = styled.div`
    width: 448px;
    height: 184px;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 20px;
    display: inline-flex;

    font-size: 16px;
    font-weight: 500;
    line-height: 22px;
`;

export const SocialLoginButton = styled.button`
    align-self: stretch;
    height: 48px;
    padding: 10px 16px;
    background: white;
    border-radius: 8px;
    overflow: hidden;
    border: 1px #E7E7E7 solid;
    justify-content: center;
    align-items: center;
    gap: 10px;
    display: inline-flex;

    &:hover {
        background: #F7F7F7;
        cursor: pointer;
    }
`;

export const SocialLoginIcon = styled.img`
    width: 24px;
    height: 24px;
`;

export const Divider = styled.div`
    display: flex;
    width: 100%;
    text-align: center;
    color: #E7E7E7;
    font-size: 14px;
    align-items: center;
    margin: 40px 0;

    p {
        text-align: center;
        color: #E7E7E7;
        font-size: 14px;
        font-weight: 500;
        line-height: 16px;
        letter-spacing: 1px;
        margin: 0;
    }

    &::before,
    &::after {
        content: '';
        flex: 1;
        border-bottom: 1px solid #E7E7E7;
    }

    &::before {
        margin-right: 10px;
    }

    &::after {
        margin-left: 10px;
    }
`;

export const FormContainer = styled.form`
    width: 448px;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;
    display: inline-flex;
`;

export const LoginInputLabelStyle = styled.label`
    font-size: 16px;
    font-weight: 500;
    line-height: 22px;
    margin-bottom: 10px;
`;

export const LoginInputTextStyle = styled.input`
    align-self: stretch;
    height: 48px;
    padding: 10px 16px;
    background: white;
    border-radius: 8px;
    overflow: hidden;
    border: 1px #E7E7E7 solid;
    display: inline-flex;
    margin-bottom: 24px;
    color: #5A5A5A;
    font-size: 16px;
    font-weight: 500;
    line-height: 22px;

    &::placeholder {
        color: #959595;
        font-size: 16px;
        font-weight: 300;
        line-height: 22px;
    }
`;

// 버튼 스타일
export const LoginButtonStyle = styled.button`
    width: 448px;
    height: 48px;
    padding: 12px 20px;
    background: ${({ disabled }) => (disabled ? '#D9D9D9' : '#30B0C7')};
    border-radius: 8px;
    justify-content: center;
    align-items: center;
    display: inline-flex;
    border: none;
    color: white;
    font-size: 16px;
    font-weight: 700;
    line-height: 22px;
    cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
    pointer-events: ${({ disabled }) => (disabled ? 'none' : 'auto')};

    &:hover {
        background: ${({ disabled }) => (disabled ? '#D9D9D9' : '#2A9BB0')};
    }
`;

export const Wrapper = styled.div`
    width: 448px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 4px;
    margin: 24px 0;
`;

export const Text = styled.div`
    color: ${(props) => props.color || '#30B0C7'};
    font-size: 14px;
    font-weight: ${(props) => props.fontWeight || '700'};
    line-height: 20px;
    text-decoration: none;
`;

export const TermsText = styled(Text)`
    text-align: center;
    color: #E7E7E7;
    font-size: 14px;
    font-weight: 500;
    line-height: 22px;
`;

export const TermsLink = styled.a`
    text-decoration: underline;
    cursor: pointer;
`;

export const GoToLogin = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin: 20px 0;

    span {
        text-align: center;
        color: #5A5A5A;
        font-size: 14px;
        font-weight: 400;
        line-height: 18px;
    }
`;

export const GoToLoginButton = styled.button`
    color: #30B0C7;
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
    border: none;
    background: none;
    cursor: pointer;

    &:hover {
        text-decoration: underline;
    }
`;
