import axios from 'axios';
import {queryString} from '../../utils/queryString';

/*

 */

export const getSearch = async (id, name) => {

    const BACKEND_URL = process.env.REACT_APP_DASHBOARD_URL;

    try {
        const list = {
            // size: '1'
            fileName: encodeURIComponent(name)
        }

        const response = await axios.get(`${BACKEND_URL}/user/${id}/search?${queryString(list)}`, {
            withCredentials: true,
        });

        console.log('url: ', `${BACKEND_URL}/user/${id}/search?${queryString(list)}`);

        console.log(response.data);

        return response.data;
    } catch (error) {
        console.error(error);
    }
}

