// src/apis/youtubeData.js
const API_KEY = 'AIzaSyDb1ZurlaYLUJ4SnQ58SP5YLBzTxALRGSc'; // Replace with your YouTube Data API key

// Function to extract video ID from YouTube URL
export const extractVideoId = (url) => {
    // Normalize the URL
    const normalizedUrl = new URL(url);
    // Split the URL path into parts
    const pathParts = normalizedUrl.pathname.split('/');

    // Check if the URL is a valid YouTube URL
    return pathParts[pathParts.length - 1] || normalizedUrl.searchParams.get('v') || null;
};

// Function to fetch video info from YouTube API
export const fetchVideoInfo = async (youtubeUrl, setVideoTitle, setThumbnailUrl) => {
    // Extract video ID from the URL
    const videoId = extractVideoId(youtubeUrl);
    // Check if the video ID is valid
    if (videoId) {
        try {
            const response = await fetch(`https://www.googleapis.com/youtube/v3/videos?part=snippet&id=${videoId}&key=${API_KEY}`);
            const data = await response.json();

            // Check if the response contains video info
            if (data.items && data.items.length > 0) {
                const videoInfo = data.items[0].snippet;
                setVideoTitle(videoInfo.title);
                setThumbnailUrl(videoInfo.thumbnails.high.url);
            }

        } catch (error) {
            console.error('Error fetching video info:', error);
        }
    } else {
        alert('유효한 YouTube URL을 입력해주세요.');
    }
};
