// redux/alertSlice.js
import { createSlice } from '@reduxjs/toolkit';

const alertSlice = createSlice({
    name: 'alert',
    initialState: {
        isVisible: false,
        title: '',
        message: '',
        route: null,
    },
    reducers: {
        showAlert: (state, action) => {
            const { title, message, route } = action.payload;
            state.isVisible = true;
            state.title = title;
            state.message = message;
            state.route = route;
        },
        hideAlert: (state) => {
            state.isVisible = false;
            state.title = '';
            state.message = '';
            state.route = null;
        },
    },
});

export const { showAlert, hideAlert } = alertSlice.actions;
export default alertSlice.reducer;
