import React, {useState, useEffect} from 'react';
import MenuBar from '../../../components/menubar/MenuBar';
import {
    Content,
    LeftContent,
    MainContainer,
    MainContent,
    RightContent,
    SubDownloadButton,
    Title,
    VideoInfo,
    VideoInfoContainer,
    VideoInfoRow,
    VideoTitle
} from '../../../styles/styles';
import Navigation from "../../../components/sidebar/Nav";
import {
    TimelineContainer,
    TimelineHeader,
    Card,
    SearchTextContainer,
    SearchText,
    CardText,
    CardTime,
    CardContainer,
    SpeakerOptionContainer,
    PaginationButton
} from "../../../styles/TimelineStyles";
import Tab from "../../../components/tab/Tabs";
import {IoSearchOutline} from "react-icons/io5";
import {FaUser} from "react-icons/fa";
import DropDown from "../../../components/DropDown";
import {useLocation} from "react-router-dom";
import {MdArrowBackIosNew, MdArrowForwardIos} from "react-icons/md";
import {formatTime} from "../../../utils/formatTime";
import axiosInstance from "../../../apis/utils/axiosConfig";

const DOWNLOAD_URL = process.env.REACT_APP_DOWNLOAD_URL;

const SubtitleGenerationPage = () => {
    const location = useLocation();
    let {result} = location.state || {};
    const [searchQuery, setSearchQuery] = useState("");
    const [resultEmotions, setResultEmotions] = useState([]);
    const [selectedSpeaker, setSelectedSpeaker] = useState("전체");
    const [currentPage, setCurrentPage] = useState(1);
    const [options, setOptions] = useState([]);
    const itemsPerPage = 5; // 페이지당 카드 수
    const [isEditMode, setIsEditMode] = useState(false); // 전체 카드 수정 모드
    const [cardTexts, setCardTexts] = useState({});  // 각 페이지의 카드 텍스트 상태 저장
    const videoUrl = result.youtubeInfo ? `${DOWNLOAD_URL}/${result.youtubeInfo.videoPath}` : `${DOWNLOAD_URL}/${result.originalFilePath}`
    const subtitleUrl = `${DOWNLOAD_URL}/${result.vtt}`;
    const emotionMap = {
        HAPPINESS: {text: '기쁨', color: '#DBA324', background: '#FFFFD4'},
        ANGRY: {text: '분노', color: '#D02D26', background: '#FFEFEE'},
        NEUTRAL: {text: '보통', color: '#14AE5C', background: '#EDFBCB'},
        SADNESS: {text: '슬픔', color: '#267E98', background: '#F0FFFF'},
        SURPRISE: {text: '놀람', color: '#FF69B4', background: '#F0FFFF'}
    };

    const getPaginatedResults = (results, page) => {
        const start = (page - 1) * itemsPerPage;
        const end = start + itemsPerPage;
        return results.slice(start, end);
    };

    useEffect(() => {
        if (resultEmotions && Object.keys(resultEmotions).length === result.length) {
            addEmotion();
        }
    }, [resultEmotions]);

    const addEmotion = () => {
        result.forEach((item, index) => {
            item.emotions = resultEmotions[index];
        });
        console.log('result', result);
    };

    useEffect(() => {
        if (result) {
            const speakers = Array.from(new Set(result.subtitle.map(r => `화자 ${r.speaker}`)));
            setOptions(["전체", ...speakers]);

            // 각 페이지별 텍스트 상태를 초기화
            const initialTexts = result.subtitle.map(r => r.text);
            const initialCardTexts = {};
            const totalPages = Math.ceil(result.length / itemsPerPage);

            for (let page = 1; page <= totalPages; page++) {
                initialCardTexts[page] = getPaginatedResults(result, page).reduce((acc, r, index) => {
                    acc[index] = r.text;
                    return acc;
                }, {});
            }
            setCardTexts(initialCardTexts);
            console.log('result', result);
        }
    }, [result]);

    const filteredResult = result ? result.subtitle.filter(r =>
        selectedSpeaker === "전체" || `화자 ${r.speaker}` === selectedSpeaker
    ) : [];

    const paginatedResult = getPaginatedResults(filteredResult, currentPage);

    const handleSpeakerSelect = (option) => {
        setSelectedSpeaker(option);
        setCurrentPage(1); // 필터링 후 첫 페이지로 이동
    };

    const handlePageChange = (direction) => {
        setCurrentPage(prevPage => {
            if (direction === "next") {
                return prevPage + 1;
            } else if (direction === "prev") {
                return Math.max(prevPage - 1, 1);
            }
            return prevPage;
        });
    };

    const handleEditToggle = () => {
        if (isEditMode) {
            // 저장 모드일 경우
            const updatedResult = [...result];

            // 각 페이지의 cardTexts에 저장된 텍스트를 result에 반영
            Object.keys(cardTexts).forEach((page) => {
                const startIndex = (page - 1) * itemsPerPage;
                Object.keys(cardTexts[page]).forEach((index) => {
                    updatedResult[startIndex + Number(index)].text = cardTexts[page][index];
                });
            });

            result = updatedResult;
        }

        setIsEditMode(!isEditMode); // 수정 모드 토글
    };


    const handleTextChange = (index, newText) => {
        setCardTexts(prevTexts => ({
            ...prevTexts,
            [currentPage]: {
                ...prevTexts[currentPage],
                [index]: newText
            }
        }));
    };

    const highlightText = (text, query) => {
        if (!query) return text;
        const parts = text.split(new RegExp(`(${query})`, 'gi'));
        return parts.map((part, index) =>
            part.toLowerCase() === query.toLowerCase() ? (
                <span key={index} style={{backgroundColor: 'yellow'}}>{part}</span>
            ) : part
        );
    };

    const getFileNameWithoutExtension = (filePath) => {
        return filePath.split('/').pop();
    };

    const handleDownload = async () => {
        const downloadUrl = `${DOWNLOAD_URL}/${result.srt}`;

        try {
            const response = await axiosInstance.get(downloadUrl);

            if (!response.ok) throw new Error('Network response was not ok');
            const blob = await response.blob();
            const url = window.URL.createObjectURL(blob);

            const link = document.createElement('a');
            link.href = url;
            link.download = getFileNameWithoutExtension(result.srt);
            document.body.appendChild(link);
            link.click();
            link.remove();
            window.URL.revokeObjectURL(url);
        } catch (error) {
            console.error('Error downloading file:', error);
        }
    };

    return (
        <MainContainer>
            <MenuBar/>
            <Content>
                <Navigation/>
                <MainContent>
                    <LeftContent>
                        <Title>자막 생성기</Title>
                        <video controls style={{width: '100%', height: 'auto', borderRadius: '12px'}}>
                            <source src={videoUrl} type="video/mp4"/>
                            <source src={videoUrl} type="video/avi"/>
                            <source src={videoUrl} type="video/mov"/>
                            <source src={videoUrl} type="video/ogg"/>
                            <source src={videoUrl} type="video/webm"/>
                            <source src={videoUrl} type="audio/mp3"/>
                            <source src={videoUrl} type="audio/aac"/>
                            <source src={videoUrl} type="audio/flac"/>
                            <source src={videoUrl} type="audio/wav"/>
                            <source src={videoUrl} type="audio/webm"/>
                            <track
                                src={subtitleUrl}
                                kind="subtitles"
                                label="Subtitles"
                            />
                        </video>
                        <VideoInfoContainer>
                            <VideoInfoRow>
                                <VideoTitle>{result.youtubeInfo ? result.youtubeInfo.metadata.title : (getFileNameWithoutExtension(result.signal_processing.media_path))}</VideoTitle>
                            </VideoInfoRow>
                            {/*<VideoInfo>{result.s2t.decoding_start_time}</VideoInfo>*/}
                        </VideoInfoContainer>
                        <Tab
                            result={result}
                        />
                    </LeftContent>
                    <RightContent>
                        <TimelineContainer>
                            <div style={{height: 72}}/>
                            <TimelineHeader>
                                <SpeakerOptionContainer>
                                    <FaUser size={15}/>
                                    <DropDown
                                        options={options}
                                        selectedOption={selectedSpeaker}
                                        onSelect={handleSpeakerSelect}
                                    />
                                </SpeakerOptionContainer>
                                <SearchTextContainer>
                                    <IoSearchOutline size={15}/>
                                    <SearchText
                                        placeholder="검색할 자막을 입력하세요"
                                        value={searchQuery}
                                        onChange={(e) => setSearchQuery(e.target.value)}
                                    /></SearchTextContainer>
                                <button className='edit' onClick={handleEditToggle}>
                                    {isEditMode ? '저장' : '수정'}
                                </button>
                                {result && result.srt && (
                                    <SubDownloadButton onClick={handleDownload}>
                                        자막 다운로드
                                    </SubDownloadButton>
                                )}
                            </TimelineHeader>
                            <CardContainer>
                                {paginatedResult.map((r, index) => {
                                    // 현재 페이지에서의 실제 index 계산
                                    const actualIndex = (currentPage - 1) * itemsPerPage + index;
                                    // // resultEmotions에서 현재 페이지에 맞는 감정 데이터 가져오기
                                    // const emotionData = emotionMap[resultEmotions[actualIndex]];

                                    return (
                                        <Card key={index} score={r.score}>
                                            <div className="speaker-emotion">
                                                {r.speaker && <p className="speaker">화자 {r.speaker}</p>}
                                                {/*{emotionData && (*/}
                                                {/*    <p className="emotions" style={{*/}
                                                {/*        color: emotionData.color,*/}
                                                {/*        background: emotionData.background*/}
                                                {/*    }}>*/}
                                                {/*        {emotionData.text}*/}
                                                {/*    </p>*/}
                                                {/*)}*/}
                                                {r.score &&
                                                    <p className="score">{r.score <= -0.8 ? '부정확' : r.score <= -0.5 ? '부분 정확' : r.score <= -0.3 ? '대체로 정확' : '매우 정확'}</p>}
                                            </div>
                                            <CardText>
                                                {isEditMode ? (
                                                    <textarea
                                                        value={cardTexts[currentPage] ? cardTexts[currentPage][index] : r.text}
                                                        onChange={(e) => handleTextChange(index, e.target.value)}
                                                        style={{width: '100%', height: '80px'}}
                                                    />
                                                ) : (
                                                    highlightText(r.text, searchQuery)
                                                )}
                                                <CardTime>
                                                    {formatTime(r.start)} - {formatTime(r.end)}
                                                </CardTime>
                                            </CardText>
                                        </Card>
                                    );
                                })}
                            </CardContainer>
                            <div style={{display: 'flex', justifyContent: 'end', marginTop: '20px'}}>
                                <PaginationButton
                                    style={{borderRadius: '6px 0 0 6px'}}
                                    onClick={() => handlePageChange('prev')}
                                    disabled={currentPage === 1}
                                >
                                    <MdArrowBackIosNew/>
                                </PaginationButton>
                                <PaginationButton
                                    onClick={() => handlePageChange('next')}
                                    disabled={paginatedResult.length < itemsPerPage}
                                >
                                    <MdArrowForwardIos/>
                                </PaginationButton>
                            </div>
                        </TimelineContainer>
                    </RightContent>
                </MainContent>
            </Content>
        </MainContainer>
    );
};

export default SubtitleGenerationPage;
