import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {dummycall} from "../../apis/utils/dummy";
import axiosInstance from "../../apis/utils/axiosConfig";
import axios from "axios";
import store from "../index";

const BACKEND_URL = process.env.REACT_APP_DASHBOARD_URL;

// API 호출을 위한 비동기 Thunk 생성
export const fetchProjects = createAsyncThunk(
    'projects/fetchProjects',
    async (_, { rejectWithValue }) => {
        const state = store.getState();
        const accessToken = state.auth.accessToken;

        console.log('accessToken', accessToken);

        try {
            await dummycall();

            const response = await axiosInstance.get(`${BACKEND_URL}/user/project/list?size=5`);
            console.log('response', response.data.content);

            if (!response.data || !response.data.content || !response.data.content.length) {
                return rejectWithValue('프로젝트가 없습니다.');
            }
            return response.data.content; // 서버에서 받아온 프로젝트 목록
        } catch (error) {
            return rejectWithValue(error.response?.data || '알 수 없는 에러');
        }
    }
);

const projectsSlice = createSlice({
    name: 'projects',
    initialState: {
        items: [],
        status: 'idle',
        error: null,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchProjects.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchProjects.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.items = action.payload; // 프로젝트 목록 업데이트
            })
            .addCase(fetchProjects.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload;
            });
    },
});

export default projectsSlice.reducer;
