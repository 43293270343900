import React, {useCallback, useState} from 'react';
import {
    CheckboxCell,
    FileIcon, SearchContainer, SearchIcon, SearchInput, Status,
    StyledTable,
    TableCell,
    TableContainer,
    TableHeader,
    ButtonGroup,
    Button,
    TableRow, TopBar, ItemsPerPageContainer, Pagination, StyledSelect, NavigationButton
} from "../styles/FileTableStyles";
import {BiSearch} from "react-icons/bi";
import {useDispatch} from "react-redux";
import {fetchSearchData} from "../redux/slices/searchSlice";
import { FaMicrophone } from "react-icons/fa6";

const FileTable = ({list}) => {
    const dispatch = useDispatch();

    const [searchTerm, setSearchTerm] = useState(''); // 검색어 상태 추가

    const [sortColumn, setSortColumn] = useState(null);
    const [sortDirection, setSortDirection] = useState(null);

    // 페이지네이션 상태 추가
    const [itemsPerPage, setItemsPerPage] = useState(10); // 페이지당 항목 수 기본값
    const [currentPage, setCurrentPage] = useState(1);   // 현재 페이지 번호

    const userId = localStorage.getItem('userId');


    const handleSort = (column) => {
        let direction = 'asc';
        if (sortColumn === column && sortDirection === 'asc') {
            direction = 'desc';
        }
        setSortColumn(column);
        setSortDirection(direction);

        const sortedData = [...list].sort((a, b) => {
            const aValue = a[column] || '';
            const bValue = b[column] || '';

            // 파일 크기 정렬
            if (column === 'fileSize') {
                const getSizeInBytes = (size) => {
                    const unit = size.slice(-2); // 'MB', 'GB' 등 추출
                    const value = parseFloat(size.slice(0, -2)); // 숫자 추출
                    if (unit === 'GB') return value * 1024;
                    if (unit === 'MB') return value;
                    return 0; // 알 수 없는 단위는 0 처리
                };
                return direction === 'asc'
                    ? getSizeInBytes(aValue) - getSizeInBytes(bValue)
                    : getSizeInBytes(bValue) - getSizeInBytes(aValue);
            }

            // 날짜 정렬
            if (column === 'createdAt') {
                return direction === 'asc'
                    ? new Date(aValue) - new Date(bValue)
                    : new Date(bValue) - new Date(aValue);
            }

            // 기본 문자열 정렬
            if (aValue < bValue) return direction === 'asc' ? -1 : 1;
            if (aValue > bValue) return direction === 'asc' ? 1 : -1;
            return 0;
        });
        list = sortedData;
    };

    const handleSearchSubmit = (e) => {
        e.preventDefault(); // 폼 제출의 기본 동작 막기
        console.log('handleSearchSubmit');
        if (searchTerm.trim()) {
            console.log(searchTerm);
            dispatch(fetchSearchData({userId, searchTerm}));
        }
    };

    // 검색 입력 핸들러
    const handleSearchChange = (e) => {
        setSearchTerm(e.target.value);
    };

    // 페이지당 항목 수 변경 함수
    const handleItemsPerPageChange = (e) => {
        setItemsPerPage(parseInt(e.target.value, 10));
        setCurrentPage(1); // 항목 수 변경 시 첫 페이지로 이동
    };

    // 페이지 변경 함수
    const handleNextPage = () => {
        if (currentPage < Math.ceil(list.length / itemsPerPage)) {
            setCurrentPage(currentPage + 1);
        }
    };

    const handlePrevPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    // 현재 페이지에 해당하는 데이터 계산
    const paginatedList = list.slice(
        (currentPage - 1) * itemsPerPage,
        currentPage * itemsPerPage
    );


    return (
        <div>
            <TopBar>
                <div>
                    <SearchContainer>
                        <SearchIcon>
                            <BiSearch size={16}/>
                        </SearchIcon>
                        <SearchInput
                            type="text"
                            placeholder="검색"
                            value={searchTerm}
                            onChange={handleSearchChange}
                        />
                    </SearchContainer>
                </div>
                <ButtonGroup>
                    <Button onClick={handleSearchSubmit}>
                        검색
                    </Button>
                    <Button>
                        {/*<Filter size={16} />*/}
                        필터
                    </Button>
                    <Button>
                        열 맞춤설정
                    </Button>
                </ButtonGroup>
            </TopBar>
            <TableContainer>
                <StyledTable>
                    <thead>
                    <tr>
                        {/*<TableHeader onClick={handleSelectAll}>*/}
                        {/*    <input*/}
                        {/*        type="checkbox"*/}
                        {/*        checked={isAllChecked}*/}
                        {/*        onChange={handleSelectAll}*/}
                        {/*    />*/}
                        {/*</TableHeader>*/}
                        <TableHeader onClick={() => handleSort('thumbnail')}>파일 형식</TableHeader>
                        <TableHeader onClick={() => handleSort('uploadedFileName')}>파일명</TableHeader>
                        <TableHeader onClick={() => handleSort('fileSize')}>파일 크기</TableHeader>
                        <TableHeader onClick={() => handleSort('duration')}>재생시간</TableHeader>
                        <TableHeader onClick={() => handleSort('createdAt')}>생성일시</TableHeader>
                        <TableHeader onClick={() => handleSort('decodingTime')}>분석 시간</TableHeader>
                        <TableHeader onClick={() => handleSort('status')}>주제 레이블링</TableHeader>
                    </tr>
                    </thead>
                    <tbody>
                    {paginatedList.map((item, index) => (
                        <TableRow key={index}>
                            {/*<CheckboxCell>*/}
                            {/*    <input*/}
                            {/*        type="checkbox"*/}
                            {/*        checked={item.checked}*/}
                            {/*        onChange={() => handleCheckboxChange(item.id)}*/}
                            {/*    />*/}
                            {/*</CheckboxCell>*/}
                            <TableCell>
                                {item.thumbnail ? <FileIcon src={item.thumbnail} alt="thumbnail"/>
                                    :<div style={{

                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        width: '100%',
                                        height: '100%',

                                    }}>
                                        <FaMicrophone size={19} />
                                    </div>
                                }
                            </TableCell>
                            <TableCell>{item.uploadedFileName}</TableCell>
                            <TableCell>{item.fileSize}MB</TableCell>
                            <TableCell>{item.duration}</TableCell>
                            <TableCell>{item.createdAt}</TableCell>
                            <TableCell>{item.decodingTime}</TableCell>
                            <TableCell>
                                <Status status={item.status}>{item.status}</Status>
                            </TableCell>
                        </TableRow>
                    ))}
                    </tbody>
                </StyledTable>
                <Pagination>
                    {/* 페이지당 항목 수 선택 */}
                    <ItemsPerPageContainer>
                        <label>페이지당 항목 수:</label>
                        <StyledSelect value={itemsPerPage} onChange={handleItemsPerPageChange}>
                            <option value={10}>10</option>
                            <option value={20}>20</option>
                            <option value={30}>30</option>
                        </StyledSelect>
                    </ItemsPerPageContainer>
                    {/* 페이지네이션 컨트롤 */}
                    <div style={{display: 'flex'}}>
                        <NavigationButton
                            onClick={handlePrevPage} disabled={currentPage === 1}
                        >
                            ◀
                        </NavigationButton>
                        <NavigationButton
                            onClick={handleNextPage}
                            disabled={currentPage === Math.ceil(list.length / itemsPerPage)}
                        >
                            ▶
                        </NavigationButton>
                    </div>
                    {/*<PaginationContainer>*/}
                    {/*    <button onClick={handlePrevPage} disabled={currentPage === 1}>*/}
                    {/*        ◀*/}
                    {/*    </button>*/}
                    {/*    <span>*/}
                    {/*    페이지 {currentPage} / {Math.ceil(list.length / itemsPerPage)}*/}
                    {/*</span>*/}
                    {/*    <button*/}
                    {/*        onClick={handleNextPage}*/}
                    {/*        disabled={currentPage === Math.ceil(list.length / itemsPerPage)}*/}
                    {/*    >*/}
                    {/*        ▶*/}
                    {/*    </button>*/}
                    {/*</PaginationContainer>*/}
                </Pagination>
            </TableContainer>
        </div>
    );
};

export default FileTable;
