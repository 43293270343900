import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
  height: 587px;
  background: white;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
`;

export const ContentSection = styled.div`
  padding: 150px 0;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  gap: 121px;
`;

export const InfoBlock = styled.div`
  width: 456px;
  height: 189px;
  position: relative;
`;

export const CompanyName = styled.div`
    width: 201px;
    position: absolute;
    color: black;
    font-size: 30px;
    font-weight: 600;
    word-wrap: break-word;
`;

export const CompanyDescription = styled.div`
  width: 456px;
  height: 72px;
  position: absolute;
  top: 55px;
  color: #686868;
  font-size: 18px;
  font-weight: 400;
  line-height: 26px;
  word-wrap: break-word;
`;

export const ContactInfo = styled.a`
    width: 343px;
    position: absolute;
    top: 139px;
    color: black;
    font-size: 17px;
    font-weight: 500;
    line-height: 50px;
    word-wrap: break-word;
    text-decoration: none; /* 링크 밑줄 제거 */
    cursor: pointer;

    &:hover {
        text-decoration: underline; /* 마우스 오버 시 밑줄 표시 */
    }
`;

export const MenuBlock = styled.div`
  width: ${({ width }) => width}px;
  height: ${({ height }) => height}px;
  position: relative;
`;

export const MenuTitle = styled.div`
  position: absolute;
  color: black;
  font-size: 20px;
  font-weight: 600;
  word-wrap: break-word;
`;

export const MenuItems = styled.div`
  position: absolute;
  top: 49px;
  color: #686868;
  font-size: 16px;
  font-weight: 500;
  line-height: 55px;
  word-wrap: break-word;
`;

export const Footer = styled.div`
  width: 100%;
  height: 73px;
  padding: 22px 120px;
  background: #30b0c7;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 10px;
`;

export const FooterContent = styled.div`
  flex: 1;
  height: 28px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const FooterText = styled.div`
    color: white;
    font-size: 18px;
    font-weight: 500;
    line-height: 28px;
    letter-spacing: 1px;
    word-wrap: break-word;
`;
