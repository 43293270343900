// src/redux/slices/uploadSlice.js
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    file: null,
    youtubeUrl: "",
    isLoading: false,
    result: [],
    progress: { step: 0, percent: 0 },
    videoTitle: '',
    thumbnailUrl: '',
    numSpeakers: 0,
    selectedInstruments: []
};

const uploadSlice = createSlice({
    name: 'upload',
    initialState,
    reducers: {
        setFile: (state, action) => {
            state.file = action.payload;
        },
        setYoutubeUrl: (state, action) => {
            state.youtubeUrl = action.payload;
        },
        setIsLoading: (state, action) => {
            state.isLoading = action.payload;
        },
        setResult: (state, action) => {
            state.result = action.payload;
        },
        setProgress: (state, action) => {
            state.progress = action.payload;
        },
        setVideoTitle: (state, action) => {
            state.videoTitle = action.payload;
        },
        setThumbnailUrl: (state, action) => {
            state.thumbnailUrl = action.payload;
        },
        setNumSpeakers: (state, action) => {
            state.numSpeakers = action.payload;
        },
        setSelectedInstruments: (state, action) => {
            state.selectedInstruments = action.payload;
        },
        toggleInstrumentSelection: (state, action) => {
            if (state.selectedInstruments.includes(action.payload)) {
                state.selectedInstruments = state.selectedInstruments.filter(
                    (instrument) => instrument !== action.payload
                );
            } else {
                state.selectedInstruments.push(action.payload);
            }
        }
    }
});

export const {
    setFile,
    setYoutubeUrl,
    setIsLoading,
    setResult,
    setProgress,
    setVideoTitle,
    setThumbnailUrl,
    setNumSpeakers,
    setSelectedInstruments,
    toggleInstrumentSelection
} = uploadSlice.actions;

export default uploadSlice.reducer;
