import MenuBar from "../../components/menubar/MenuBar";
import Navigation from "../../components/sidebar/Nav";
import React from "react";
import {Content, MainContainer, MainContent, EmptySpace, Title, SubTitle} from "../../styles/styles";
import {useNavigate} from "react-router-dom";
import {
    CustomBtn,
    CustomBtnContainer,
    CustomContainer,
} from "./NewFlowStyles";
import {setProductId, fetchProjectData} from '../../redux/slices/productSlice';
import {useDispatch, useSelector} from "react-redux";
import curation from "../../assets/newflow/newflow_curation.svg";
import custom from "../../assets/newflow/newflow_custom.svg";
import subtitle from "../../assets/newflow/newflow_subtitle.svg";
import separate from "../../assets/newflow/newflow_separate.svg";
import dubbing from "../../assets/newflow/newflow_dubbing.svg";
import {setActiveButton} from "../../redux/slices/navigationSlice";

const NewFlowPage = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const handleCurationClick = () => {
        navigate('/curation');
    };

    const handleNavigation = (path, product, title) => {
        console.log('product', product);
        navigate(path , {state: {product, title}});
    };

    const handleProjectClick = async (productId, product) => {
        dispatch(setActiveButton("")); // 상태 업데이트
        dispatch(setProductId(productId)); // Redux에 productId 저장

        // API 호출
        await dispatch(fetchProjectData(productId));
        handleNavigation('/upload', product, '빠른 시작'); // Upload 페이지로 이동
    };

    return (
        <MainContainer>
            <MenuBar/>
            <Content>
                <Navigation/>
                <MainContent>
                        <Title>새로운 플로우</Title>
                        <CustomContainer>
                            <SubTitle>맞춤형 시작</SubTitle>
                            <p>옵션 선택으로 최적화 작업</p>
                            <CustomBtnContainer>
                                <CustomBtn onClick={handleCurationClick}>
                                    <img src={curation} alt="curation"/>
                                    <h3>큐레이션 탐색</h3>
                                    <span>필요한 기능을 제안받아 바로 적용하세요.</span></CustomBtn>
                                <CustomBtn onClick={handleCurationClick}>
                                    <img src={custom} alt="custom"/>
                                    <h3>커스텀 설정</h3>
                                    <span>필요한 기능만 선택해 조합해 보세요.</span></CustomBtn>
                            </CustomBtnContainer>
                        </CustomContainer>
                        <CustomContainer>
                            <SubTitle>빠른 시작</SubTitle>
                            <p>완성된 제품으로 간편하게 작업</p>
                            <CustomBtnContainer>
                                <CustomBtn onClick={() => handleProjectClick('audion_sg', 'subtitle')}>
                                    <img src={subtitle} alt="subtitle"/>
                                    <h3>자막 생성</h3>
                                    <span>정확하고 빠르게 자막을 생성하세요.</span></CustomBtn>
                                <CustomBtn onClick={() => handleProjectClick('audion_vs', 'source-separation')}>
                                    <img src={separate} alt="separate"/>
                                    <h3>음성·음원 분리</h3>
                                    <span>음성과 음원을 분리해 깔끔히 추출하세요.</span></CustomBtn>
                                <CustomBtn onClick={() => handleProjectClick('audion_mss', 'source-separation')}>
                                    <img src={dubbing} alt="dubbing" style={{height: '25px'}}/>
                                    <h3>자동 더빙</h3>
                                    <span>손쉽게 완성도 높은 더빙을 만들어 보세요.</span></CustomBtn>
                            </CustomBtnContainer>
                        </CustomContainer>
                        <EmptySpace/>
                </MainContent>
            </Content>
        </MainContainer>
    );
};

export default NewFlowPage;
