import React, { useState, useEffect, useRef } from 'react';
import { Desc, TabMenu, ChatContainer, ChatBox, InputContainer, InputBox, SendButton, ChatMessage } from "./TabsStyles";
import { FaArrowAltCircleUp } from "react-icons/fa";
import { handleInsight } from "../../apis/product/subtitle_mllm";
import {formatTime} from "../../utils/formatTime";



const Tab = ({ result }) => {
    const [currentTab, clickTab] = useState(0);
    const [chatHistory, setChatHistory] = useState([]);
    const [currentInput, setCurrentInput] = useState("");
    const [isLoading, setIsLoading] = useState(false);  // 로딩 상태 관리
    const chatBoxRef = useRef(null);  // ChatBox를 참조할 수 있는 ref 생성

    const metaArr = Object.keys(result.signal_processing.media_info).map((key, index) => (
        <div key={index}>
            <p><strong>{key}:</strong> {result.signal_processing.media_info[key]}</p>
        </div>
    ));

    // result.score 중에 -0.5 이하인 경우에만 필터링, 타임스탬프와 함께 반환
    const filteredResult = result !== "" && result.subtitle.filter((el) => el.score <= -0.5).map((el) => (
        <li style={{fontSize: '14px', lineHeight: '24px', textAlign:'left'}}><strong>{formatTime(el.start)}:{formatTime(el.end)}</strong> {el.text}</li>
    ));
    // 결과가 없을 경우 출력할 메시지
    const noResultsMessage = <p style={{fontSize: '14px'}}>어시스턴트할 결과가 없습니다.</p>;

    const menuArr = [
        { name: '텍스트', content: result.text },
        { name: '요약', content: result.summary ? result.summary.replace(/^#+\s*/, '') : "요약 생성에 실패했습니다. \n 인사이트 탭을 활용해 주세요." },
        { name: '인사이트', content: '' },
        { name: '어시스턴트', content: filteredResult.length > 0 ? filteredResult : noResultsMessage },
        { name: '메타데이터', content: metaArr },
    ];

    const selectMenuHandler = (index) => {
        clickTab(index);
    };

    const handleSend = async () => {
        if (currentInput.trim()) {
            const newChat = { user: currentInput, response: "로딩 중..." }; // 로딩 표시 추가

            // 사용자의 입력을 먼저 업데이트
            setChatHistory(prevChatHistory => [...prevChatHistory, newChat]);
            setIsLoading(true);  // 로딩 상태 활성화

            try {
                // handleInsight 호출 후 응답을 업데이트
                const response = await handleInsight(currentInput);
                setChatHistory(prevChatHistory => {
                    const updatedChatHistory = [...prevChatHistory];
                    updatedChatHistory[updatedChatHistory.length - 1].response = response; // 마지막 채팅의 응답 업데이트
                    return updatedChatHistory;
                });
            } catch (error) {
                setChatHistory(prevChatHistory => {
                    const updatedChatHistory = [...prevChatHistory];
                    updatedChatHistory[updatedChatHistory.length - 1].response = "응답을 불러오는데 실패했습니다.";  // 실패 시 표시
                    return updatedChatHistory;
                });
            } finally {
                setIsLoading(false);  // 로딩 상태 해제
            }

            // 입력 필드 초기화
            setCurrentInput("");
        }
    };

    // chatHistory가 업데이트될 때마다 최신 메시지로 스크롤
    useEffect(() => {
        if (chatBoxRef.current) {
            chatBoxRef.current.scrollTop = chatBoxRef.current.scrollHeight;
        }
    }, [chatHistory]);

    return (
        <>
            <TabMenu>
                {menuArr.map((el, index) => (
                    <li
                        key={index}
                        className={index === currentTab ? "submenu focused" : "submenu"}
                        onClick={() => selectMenuHandler(index)}
                    >
                        {el.name}
                    </li>
                ))}
            </TabMenu>

            {currentTab === 2 ? ( // 인사이트 탭의 경우
                <ChatContainer>
                    <ChatBox ref={chatBoxRef}> {/* ChatBox에 ref 추가 */}
                        {chatHistory.map((chat, index) => (
                            <div key={index} style={{
                                display: "flex",
                                flexDirection: "column",
                                margin: "5px 0",
                            }}>
                                <ChatMessage user>{chat.user}</ChatMessage>
                                <ChatMessage>{chat.response}</ChatMessage>
                            </div>
                        ))}
                    </ChatBox>
                    <InputContainer>
                        <InputBox
                            type="text"
                            value={currentInput}
                            onChange={(e) => setCurrentInput(e.target.value)}
                            placeholder="질문을 입력하세요"
                            disabled={isLoading}  // 로딩 중일 때 입력 비활성화
                        />
                        <SendButton onClick={handleSend} disabled={isLoading}> {/* 로딩 중일 때 버튼 비활성화 */}
                            {isLoading ? "로딩 중..." : <FaArrowAltCircleUp size={20} />}
                        </SendButton>
                    </InputContainer>
                </ChatContainer>
            ) : (
                <Desc>
                    <p>{menuArr[currentTab].content}</p>
                </Desc>
            )}
        </>
    );
};

export default Tab;
