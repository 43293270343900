import React, {useState} from 'react';
import logo from "../../assets/signup/signup_logo.svg";
import axios from 'axios';
import {useNavigate} from "react-router-dom";
import {
    GoToLogin,
    GoToLoginButton,
    FormContainer,
    LoginButtonStyle,
    LoginContainer,
    LoginInputLabelStyle,
    LoginInputTextStyle,
    Logo,
    PageContainer,
    TermsLink,
    TermsText,
    Title
} from "../login/LoginStyles";
import useAlert from "../../hooks/useAlert";
import AlertModal from "../../components/alert/AlertModal";
import {SubTitle} from "../../styles/styles";

const Signup = () => {
    const [username, setUsername] = useState('');
    const [email, setEmail] = useState('');
    const [organization, setOrganization] = useState('');
    const [password, setPassword] = useState('');
    const [repeatPassword, setRepeatPassword] = useState('');
    const [error, setError] = useState(null);
    const navigate = useNavigate();

    const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

    const {isAlertVisible, title, message, showAlert, hideAlert} = useAlert();

    // 유효성 검사 함수들
    const checkUsername = (username) => {
        const valid = /^[a-zA-Z0-9_-]+$/.test(username);
        if (!valid) {
            setError("이름은 알파벳과 숫자만 포함해야 합니다. 특수문자는 '_'와 '-'만 허용됩니다.");
        }
        return valid;
    };

    const checkEmail = (email) => {
        const valid = email.includes("@");
        if (!valid) {
            setError("올바른 이메일 주소를 입력해주세요.");
        }
        return valid;
    };

    const checkPassword = (password) => {
        const valid = password.length >= 8 && /\d/.test(password) && /[a-zA-Z]/.test(password) && /[^a-zA-Z0-9]/.test(password);

        if (!valid) {
            setError("비밀번호는 8자 이상이어야 하며, 숫자, 문자, 특수문자를 포함해야 합니다.");
        }
        return valid;
    };

    const checkRepeatPassword = (password, repeatPassword) => {
        const valid = password === repeatPassword;
        if (!valid) {
            setError("비밀번호가 일치하지 않습니다.");
        }
        return valid;
    };

    // 폼 제출 함수
    const handleSubmit = async (e) => {
        e.preventDefault();
        setError(null);

        if (checkUsername(username) && checkEmail(email) && checkPassword(password) && checkRepeatPassword(password, repeatPassword)) {
            try {
                const response = await axios.post(`${BACKEND_URL}/signup`, {
                    username, email, organization, password,
                }, {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    withCredentials: true,
                });

                console.log(response.data);

                if (response.status === 200) {
                    setError(null);
                    showAlert('회원가입이 완료되었습니다.', '로그인 페이지로 이동합니다.', '/login');
                }
            } catch (error) {
                if (error.response && error.response.status === 400) {
                    setError("회원가입에 실패했습니다. 다시 시도해주세요.");
                    showAlert('이미 존재하는 이메일 주소입니다.', '다른 이메일 주소를 입력해주세요.');
                } else {
                    setError("서버 오류가 발생했습니다. 잠시 후 다시 시도해주세요.");
                    showAlert('서버 오류가 발생했습니다.', '잠시 후 다시 시도해주세요.');
                }
                console.error(error);
            }
        }
    };

    return (<PageContainer style={{pointerEvents: isAlertVisible ? 'none' : 'auto'}}>
        {isAlertVisible && <AlertModal title={title} message={message} onClose={hideAlert}/>}
        <LoginContainer>
            <Logo>
                <img src={logo} alt="logo"/>
            </Logo>
            <SubTitle style={{lineHeight: '44px'}}>오디온 회원가입</SubTitle>
            <FormContainer onSubmit={handleSubmit}>
                <LoginInputLabelStyle>이름/닉네임*</LoginInputLabelStyle>
                <LoginInputTextStyle
                    type="text"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                    placeholder="필수 입력 항목입니다."
                    required
                />
                <LoginInputLabelStyle>이메일*</LoginInputLabelStyle>
                <LoginInputTextStyle
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    placeholder="필수 입력 항목입니다."
                    required
                />
                <LoginInputLabelStyle>소속</LoginInputLabelStyle>
                <LoginInputTextStyle
                    type="text"
                    value={organization}
                    onChange={(e) => setOrganization(e.target.value)}
                    placeholder="회사 / 학교 / 프리랜서 등"
                />
                <LoginInputLabelStyle>비밀번호*</LoginInputLabelStyle>
                <LoginInputTextStyle
                    type="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    placeholder="영어, 숫자, 특수문자를 조합하여 8~12자 이내로 설정해주세요."
                    required
                />
                <LoginInputLabelStyle>비밀번호 확인*</LoginInputLabelStyle>
                <LoginInputTextStyle
                    type="password"
                    value={repeatPassword}
                    onChange={(e) => setRepeatPassword(e.target.value)}
                    placeholder="-"
                    required
                />
                <LoginButtonStyle type="submit">회원가입 완료</LoginButtonStyle>
            </FormContainer>
            <GoToLogin>
                <span>이미 계정이 있으신가요?</span>
                <GoToLoginButton onClick={() => navigate('/login')}>로그인</GoToLoginButton>
            </GoToLogin>
            <TermsText>
                계속하시면 오디온의 <TermsLink onClick={() => window.location.href = '/terms'}>이용약관</TermsLink> 및<br/>
                <TermsLink
                    onClick={() => window.location.href = '/privacy'}>개인정보처리방침</TermsLink>에 동의하게 됩니다.
            </TermsText>
        </LoginContainer>
    </PageContainer>);
};

export default Signup;
