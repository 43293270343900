const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

export const handleInsight = async (text) => {
    const formData = new FormData();
    formData.append('service_name', 'MllmChat');
    formData.append('text', text);
    formData.append('mode', 'chat');

    try {
        const response = await fetch(`${BACKEND_URL}/service`, {
            method: 'POST',
            credentials: 'include',
            body: formData,
        });

        if (!response.ok) {
            console.error(`Invalid POST request: ${response.status}`);
            alert(`Invalid POST request: ${response.status}`);
            return;
        }

        const data = await response.json();

        console.log('insight', data);

        return data.content.text;

    } catch (error) {
        console.error('Error generating questions and answers:', error);
        alert('Error generating questions and answers. Please try again.');
    }
};
