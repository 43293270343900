import React, {useEffect, useState} from 'react';
import MenuBar from '../../components/menubar/MenuBar';
import {useNavigate} from "react-router-dom";
import {MainContainer} from "../../styles/styles";
import {
    HeroSection,
    Section,
    HeroTitle,
    HeroImage,
    Section2,
    ProductTag,
    FeaturesGrid,
    FeatureIcon,
    FeatureTitle,
    FeatureDescription,
    LearnMore,
    FeatureCard,
    Title,
FeatureContent, Container
} from "./LandingPageStyles";
import landing_dashboard from "../../assets/img/landing_dashboard.webp";
import landing_subtitle from "../../assets/img/landing_subtitle.webp";
import product_1 from "../../assets/img/product_1.svg";
import product_2 from "../../assets/img/product_2.svg";
import product_3 from "../../assets/img/product_3.svg";
import useWindowDimensions from "../../utils/useWindowDimensions";
import * as PropTypes from "prop-types";
import VoiceCognitionPage from "../../components/Section3";
import KeyBenefits from "../../components/Section4";
import CustomizationSection from "../../components/Section5";
import SolutionPage from "../../components/Section6";
import BackgroundCircles from "../../components/Section7";
import FooterSection from "../../components/footer/Footer";

FeatureCard.propTypes = {children: PropTypes.node};
const LandingPage = () => {
    const navigate = useNavigate();
    const {height, width} = useWindowDimensions();
    const features = [
        {
            icon: '/path/to/analysis-icon.svg',
            title: '음성 이해',
            description: '대화 분석 및 검색으로\n더 똑똑한 데이터 활용',
        },
        {
            icon: '/path/to/separation-icon.svg',
            title: '음성 분리',
            description: '배경 소음 속에서도\n필요한 음성만 추출',
        },
        {
            icon: '/path/to/deeping-icon.svg',
            title: '음성 더빙',
            description: '자연스러운 더빙으로\n콘텐츠 품질 향상',
        },
    ];
    // 제품 큐레이션으로 이동
    const onClickQuration = () => {
        navigate('/home');
    }

    const [isHeroVisible, setIsHeroVisible] = useState(false);

    useEffect(() => {
        // Hero 이미지 애니메이션을 위한 IntersectionObserver
        const heroObserver = new IntersectionObserver(
            ([entry]) => {
                if (entry.isIntersecting) {
                    setIsHeroVisible(true);
                } else {
                    setIsHeroVisible(false);
                }
            },
            { threshold: 0.4 }
        );
        const heroTarget = document.querySelector(".hero-image");
        if (heroTarget) heroObserver.observe(heroTarget);

        return () => {
            heroObserver.disconnect();
        };
    }, []);

    return (
        <MainContainer>
            <MenuBar/>
            {/*<div>Width: {width} Height: {height}</div>*/}
            {/* Hero Section */}
            <Container>
            <HeroSection>
                <HeroTitle>
                    <h1>
                        코드 한줄로 해결하는 <br/>음성 AI 도입
                    </h1>
                    <p>
                        음성 AI 구현의 복잡함을 없애고, 비즈니스 가능성을 확장하세요.
                    </p>
                    <button onClick={onClickQuration}>무료로 시작하기</button>
                </HeroTitle>
                {/* 이미지 배치 */}
                <HeroImage className="hero-image">
                    <img className={isHeroVisible ? "visible" : "hidden"} src={landing_subtitle} alt="이미지 1"/>
                    <img className={isHeroVisible ? "visible" : "hidden"} src={landing_dashboard} alt="이미지 2"/>
                </HeroImage>
            </HeroSection>
            </Container>
            {/* Other Sections */}
            <Section>
                <h2>음성 AI 도입, 아직도 어렵고 복잡하신가요?</h2>
                <p>
                    <span>고성능 음성 AI를 개발하려면 시간이 오래 걸리고 비용이 많이 듭니다.</span>
                    <span>전문 인력이 없어 도입조차 고민되시나요?</span>
                    <span>오디온은 한 줄의 코드로 이 모든 문제를 해결합니다.</span>
                </p>
            </Section>
            <Section2>
                <ProductTag>Product</ProductTag>
                <Title>
                    Beyond Speech-to-Text<br/>
                    단순 음성 변환을 넘어선 AI 솔루션
                </Title>
                <FeaturesGrid>
                    {features.map((feature, index) => (
                        <FeatureCard key={index}>
                            <FeatureIcon>
                                <img src={require(`../../assets/img/product_${index + 1}.svg`)} alt={feature.title} />
                            </FeatureIcon>
                            <FeatureContent>
                            <FeatureTitle>{feature.title}</FeatureTitle>
                            <FeatureDescription>
                                {feature.description.split('\n').map((line, i) => (
                                    <React.Fragment key={i}>
                                        {line}
                                        {i === 0 && <br />}
                                    </React.Fragment>
                                ))}
                            </FeatureDescription>
                            </FeatureContent>
                            <LearnMore href="#">자세히 보기</LearnMore>
                        </FeatureCard>
                    ))}
                </FeaturesGrid>
            </Section2>
            <VoiceCognitionPage/>
            <KeyBenefits/>
            <CustomizationSection/>
            <SolutionPage/>
            <BackgroundCircles/>
            <FooterSection/>
        </MainContainer>
    );
}

export default LandingPage;
