import {
    CompanyDescription,
    CompanyName,
    ContactInfo,
    ContentSection, FooterContent, FooterText,
    InfoBlock,
    MenuBlock, MenuItems, MenuTitle, Footer,
    Wrapper
} from "./FooterStyles";

const FooterSection = () => {
    return (
        <Wrapper>
            <ContentSection>
                <InfoBlock>
                    <CompanyName>주식회사 마고</CompanyName>
                    <CompanyDescription>
                        비즈니스를 위한 최고의 음성AI 파트너
                        <br />
                        마고가 어려운 음성AI를 해결해 드립니다.
                        <br />
                        이제 비즈니스 성장에만 집중하세요.
                    </CompanyDescription>
                    <ContactInfo href="mailto:contact@holamago.com">
                        contact@holamago.com
                    </ContactInfo>
                </InfoBlock>
                <MenuBlock width={56} height={214}>
                    <MenuTitle>회사</MenuTitle>
                    <MenuItems>
                        소개
                        <br />
                        블로그
                        <br />
                        기술문서
                    </MenuItems>
                </MenuBlock>
                <MenuBlock width={166} height={214}>
                    <MenuTitle>솔루션</MenuTitle>
                    <MenuItems>
                        Media & Entertainment
                        <br />
                        HRD
                        <br />
                        AICC
                    </MenuItems>
                </MenuBlock>
                <MenuBlock width={42} height={159}>
                    <MenuTitle>자료</MenuTitle>
                    <MenuItems>
                        요금제
                        <br />
                        문서
                    </MenuItems>
                </MenuBlock>
                <MenuBlock width={88} height={159}>
                    <MenuTitle>마이페이지</MenuTitle>
                    <MenuItems>
                        로그인
                        <br />
                        문의하기
                    </MenuItems>
                </MenuBlock>
            </ContentSection>
            <Footer>
                <FooterContent>
                    <FooterText><span>Copyrights</span><span style={{marginLeft: "35px"}}>2025 mago inc. All Rights Reserved.</span></FooterText>
                    <FooterText><span>서비스 이용약관</span>
                        <span style={{marginLeft: "80px"}}>개인정보처리방침</span></FooterText>
                </FooterContent>
            </Footer>
        </Wrapper>
    );
};

export default FooterSection;
