import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchListData, fetchVolumeData } from '../../redux/slices/listSlice';
import FileData from '../../components/FileTable';
import {Content, MainContainer, MainContent, Title} from "../../styles/styles";
import MenuBar from "../../components/menubar/MenuBar";
import Navigation from "../../components/sidebar/Nav";
import {DashhboardContainer, FileBox, MediaBox, VolumeContainer} from "./DashboardStyles";
import {MdMovieEdit} from "react-icons/md";
import folder from "../../assets/dashboard/folder_icon.svg";

const DashboardPage = () => {
    const dispatch = useDispatch();
    const { list, volume, status } = useSelector((state) => state.list);
    const userId = localStorage.getItem('userId');

    useEffect(() => {
        if (userId) {
            dispatch(fetchListData(userId));
            dispatch(fetchVolumeData(userId));
        }
    }, [userId, dispatch]);

    const { totalCount, totalDuration, video, audio } = volume;

    return (
        <MainContainer>
            <MenuBar />
            <Content>
                <Navigation />
                <MainContent>
                        <Title>대시보드</Title>
                        <VolumeContainer>
                            <FileBox>
                                <div className='icon'>
                                    <img src={folder} alt={folder} />
                                </div>
                                <div className='content'>
                                    <div className='item'>
                                        <div className='title'>전체 파일</div>
                                        <div>{totalCount}개</div>
                                    </div>
                                    <div className='item'>
                                        <div className='title'>전체 파일 크기</div>
                                        <div>{totalDuration}</div>
                                    </div>
                                </div>
                            </FileBox>
                            <MediaBox>
                                <div className='icon'>
                                    <MdMovieEdit color='white' />
                                </div>
                                <div className='content'>
                                    <div className='item'>
                                        <div className='title'>비디오</div>
                                        <div>{video}</div>
                                    </div>
                                    <div className='item'>
                                        <div className='title'>오디오</div>
                                        <div>{audio}</div>
                                    </div>
                                </div>
                            </MediaBox>
                        </VolumeContainer>
                        {/* FileData 컴포넌트에 list 전달 */}
                        <FileData list={list} />
                </MainContent>
            </Content>
        </MainContainer>
    );
};

export default DashboardPage;
