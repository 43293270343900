import styled from 'styled-components';

export const TableContainer = styled.div`
    padding: 20px;
    width: 100%;
`;

export const StyledTable = styled.table`
    width: 100%;
    border-collapse: collapse;
    background-color: white;
`;

export const TableHeader = styled.th`
    padding: 12px 16px;
    text-align: left;
    border-bottom: 1px solid #e1e1e1;
    color: #5A5A5A;
    font-weight: 500;
    font-size: 14px;
`;

export const TableCell = styled.td`
    padding: 12px 16px;
    border-bottom: 1px solid #f0f0f0;
    font-size: 14px;
    color: #8E8E93;
    font-weight: 400;`;

export const TableRow = styled.tr`
    &:hover {
        background-color: #f8f9fa;
    }
`;

export const CheckboxCell = styled.td`
    width: 40px;
    text-align: center;
`;

export const FileIcon = styled.img`
    width: 76px;
    height: 40px;
    border-radius: 4px;
`;

export const Status = styled.span`
    padding: 4px 8px;
    border-radius: 4px;
    font-size: 12px;

    ${({status}) => {
    switch (status) {
        case '라이브스타일':
            return 'background-color: #e8f0fe; color: #1a73e8;';
        case '예술':
            return 'background-color: #e6f4ea; color: #1e8e3e;';
        case '자기계발':
            return 'background-color: #fce8e6; color: #d93025;';
        default:
            return '';
    }
}}
`;

export const TopBar = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 20px;
  gap: 12px;
`;

export const SearchContainer = styled.form`
    position: relative; /* 추가 */
    width: 176px;
`;

export const SearchInput = styled.input`
  width: 100%;
  padding: 8px 36px;
  border: 1px solid #e1e1e1;
  border-radius: 4px;
  font-size: 12px;
  outline: none;

  &:focus {
    border-color: #1a73e8;
  }
`;

export const SearchIcon = styled.div`
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
  color: #8E8E93;
`;

export const ButtonGroup = styled.div`
    display: flex;
    gap: 8px;
`;

export const Button = styled.button`
  display: flex;
  align-items: center;
  gap: 4px;
  padding: 8px 16px;
  border: 1px solid #e1e1e1;
  border-radius: 4px;
  background-color: white;
  cursor: pointer;
  font-size: 14px;
  &:hover {
    background-color: #f8f9fa;
  }
`;

export const Pagination = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 1rem;
`;

export const StyledSelect = styled.select`
  padding: 10px 20px;
  border: 1px solid #e2e8f0;
  border-radius: 0.375rem;
  background-color: white;
  font-size: 15px;
  color: #1a1a1a;
  cursor: pointer;
    width: 78px;
    height: 38px;

  &:focus {
    outline: none;
    border-color: #94a3b8;
  }
`;



export const ItemsPerPageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;

  label {
    margin-right: 10px;
    font-size: 15px;
      color: #8B909A;
  }

  select {
    padding: 5px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 5px;
    cursor: pointer;

    &:focus {
      outline: none;
      border-color: #007bff;
    }
  }
`;
export const PaginationContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px 0;

  button {
    background-color: white;
    color: black;
    border: 1px solid #DEE2E6;
    padding: 10px 15px;
    margin: 0 5px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 14px;

    &:disabled {
      color: #8E8E93;
      cursor: not-allowed;
    }

    &:hover:enabled {
      background-color: #DEE2E6;
    }
  }

  span {
    font-size: 16px;
    margin: 0 10px;
  }
`;
export const NavigationButton = styled.button`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 2rem;
    height: 2rem;
    border: 1px solid #DEE2E6;
    background-color: white;
    cursor: pointer;
    transition: all 0.2s;

    &:hover {
        background-color: #f8fafc;
    }

    &:disabled {
        cursor: not-allowed;
        color: #8E8E93;
    }

    svg {
        width: 1.25rem;
        height: 1.25rem;
    }
`;
