// InstrumentOption.js
import React from 'react';
import {InstrumentOptionStyles} from "./InstrumentOptionStyles";

const InstrumentOption = ({ INSTRUMENTS, handleInstrumentChange }) => (
    <InstrumentOptionStyles>
        <div className="label">악기 선택</div>
        {Object.keys(INSTRUMENTS).map((instrument) => (
            <div key={instrument}>
                <input
                    type="checkbox"
                    id={INSTRUMENTS[instrument]}
                    name={INSTRUMENTS[instrument]}
                    value={INSTRUMENTS[instrument]}
                    onChange={() => handleInstrumentChange(INSTRUMENTS[instrument])}
                />
                <label htmlFor={INSTRUMENTS[instrument]}>{instrument}</label>
            </div>
        ))}
    </InstrumentOptionStyles>
);

export default InstrumentOption;
