// src/apis/axiosConfig.js
import axios from 'axios';
import index from '../../redux'; // Redux 스토어
import {setAccessToken} from '../../redux/slices/authSlice';
import {dummycall} from "./dummy";
import {handleLogout} from "../../components/menubar/MenuBar";

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

const axiosInstance = axios.create({
    baseURL: BACKEND_URL,
    withCredentials: false, // 쿠키 포함
});

// 요청 인터셉터: Authorization 헤더 추가
axiosInstance.interceptors.request.use(
    (config) => {
        const state = index.getState();
        const accessToken = state.auth.accessToken;

        if (accessToken) {
            config.headers['Authorization'] = `Bearer ${accessToken}`;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

// 응답 인터셉터: 401 에러 처리
axiosInstance.interceptors.response.use(
    (response) => response, // 성공적인 응답은 그대로 반환
    async (error) => {
        const originalRequest = error.config;

        // 401 에러 처리
        if (error.response?.status === 401 && !originalRequest._retry) {
            originalRequest._retry = true; // 무한 반복 방지

            try {
                await dummycall();

                // Refresh Token 요청
                const refreshResponse = await axios.post(
                    `${BACKEND_URL}/auth/refreshAccessToken`,
                    { withCredentials: true } // 쿠키 포함
                );

                const newAccessToken = refreshResponse.data.content.accessToken;

                // Redux 상태 업데이트
                index.dispatch(setAccessToken(newAccessToken));

                // 새 Access Token으로 원래 요청 재시도
                originalRequest.headers['Authorization'] = `Bearer ${newAccessToken}`;
                return axiosInstance(originalRequest);
            } catch (refreshError) {
                console.error('Error refreshing access token:', refreshError);
                // 토큰 갱신 실패 시 로그아웃 또는 재로그인 로직
                alert('세션이 만료되었습니다. 다시 로그인해주세요.');
                window.location.href = '/login';
                return Promise.reject(refreshError);
            }
        }

        return Promise.reject(error);
    }
);

export default axiosInstance;
