import { useDispatch, useSelector } from 'react-redux';
import { showAlert, hideAlert } from '../redux/slices/alertSlice'; // alertSlice에서 가져옴

const useAlert = () => {
    const dispatch = useDispatch();
    const { isVisible, title, message, route } = useSelector((state) => state.alert);

    const triggerShowAlert = (title, message, route = null) => {
        dispatch(showAlert({ title, message, route }));
    };

    const triggerHideAlert = () => {
        dispatch(hideAlert());
    };

    return {
        isAlertVisible: isVisible,
        title,
        message,
        route,
        showAlert: triggerShowAlert,
        hideAlert: triggerHideAlert,
    };
};

export default useAlert;
