import React from 'react';
import styled from 'styled-components';
import solution_1 from '../assets/img/solution_1.webp';
import solution_2 from '../assets/img/solution_2.webp';
import solution_3 from '../assets/img/solution_3.webp';

export const Section2 = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 1251px;
    padding: 150px 72px;
    z-index: 2;
`;

export const ProductTag = styled.div`
    display: inline-flex;
    background-color: rgba(48, 176, 199, 0.07);
    justify-content: center;
    align-items: center;
    color: #30B0C7;
    padding: 9px 36px;
    border-radius: 10px;
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 15px;
`;

export const Title = styled.h1`
    font-size: 48px;
    font-weight: 700;
    margin-bottom: 120px;
    line-height: 64px;
    text-align: center;
`;


export const FeaturesGrid = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: start;
    gap: 48px;
    width: 100%;
    max-width: 1200px;
`;

export const FeatureCard = styled.div`
    display: flex;
    flex-direction: column;
    text-align: start;
    justify-content: start;
    align-items: start;
    border: 0.70px #DEE3EB solid;
    border-radius: 8px; /* 모서리 둥글게 */
    overflow: hidden; /* 이미지가 박스 밖으로 나오지 않게 */
    transition: transform 0.2s ease;
    width: 368px;
    height: 707px;

    &:hover {
        transform: translateY(-10px);
    }
`;

export const FeatureIcon = styled.div`
    width: 368px;

    img {
        width: 100%;
        height: 100%;
        object-fit: contain;
    }
`;

export const FeatureTitle = styled.h3`
    font-size: 30px;
    font-weight: 700;
    margin-bottom: 16px;
    line-height: 42px;
    color: #30B0C7;
`;

export const FeatureDescription = styled.div`
    line-height: 32px;
    font-size: 20px;
    
    h3 {
        font-weight: 600;
    }
    
    p {
        color: #8E8E93;
        font-weight: 500;
    }
`;

export const TextContainer = styled.div`
    padding: 24px 20px;
    position: relative; /* LearnMore 위치 고정을 위한 기준 */
    height: 100%; /* 전체 높이를 차지하도록 설정 */
`;

export const LearnMore = styled.a`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    color: #30B0C7;
    text-decoration: none;
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    position: absolute; /* 고정된 위치 */
    bottom: 24px; /* TextContainer의 하단으로 고정 */
    right: 20px; /* 오른쪽 여백 */

    &:hover {
        text-decoration: underline;
    }

    &::after {
        content: '→';
        margin-left: 8px;
    }
`;


const SolutionPage = () => {
    const features = [
        {
            icon: '/path/to/analysis-icon.svg',
            title: 'Media & Entertainment',
            subtitle1: '콘텐츠 글로벌화',
            description1: '오디온은 영상자막, 다국어 번역, 자동더빙으로 콘텐츠를 쉽게 현지화하고 글로벌 시장에 적합하게 만듭니다.',
            subtitle2: '포스트 프로덕션 단축',
            description2: '오디온은 음성 분리와 자동 싱크 기능으로 포스트 프로덕션 시간을 단축하고, 단일 미디어 뿐만 아니라 아카이빙된 대량의 데이터를 한번에 처리할 수 있도록 지원합니다.'
        },
        {
            icon: '/path/to/separation-icon.svg',
            title: 'HRD',
            subtitle1: '효율적인 음성·영상 아카이빙',
            description1: '오디온은 음성을 자막으로 변환하고 메타데이터와 함께 저장해, 검색과 재가공을 쉽게 합니다.',
            subtitle2: '학습 자료의 재구성',
            description2: '오디온은 교육 영상을 요약하고 키워드 도출, 챕터 구분 등을 통해 개인화된 학습 자료로 변환합니다.'
        },
        {
            icon: '/path/to/deeping-icon.svg',
            title: 'AICC',
            subtitle1: '정확한 음성인식',
            description1: '오디온은 보다 신속하고 정확한 음성AI 기술을 통해 AI에이전트가 사용자에게 정확한 응답을 제공할 수 있도록 지원합니다.',
            subtitle2: '고객의 마음까지 적중',
            description2: '오디온은 음성감정인식 기술을 기반으로 고객의 진짜 목소리를 듣고 고객의 실시간 감정을 파악하고 이에 대응할 수 있도록 도울 수 있습니다.'
        },
    ];

    return (
        <Section2>
            <ProductTag>Solution</ProductTag>
            <Title>
                음성 AI로 산업에 가치를 더하는 오디온
            </Title>
            <FeaturesGrid>
                {features.map((feature, index) => (
                    <FeatureCard key={index}>
                        <FeatureIcon>
                            <img src={require(`../assets/img/solution_${index + 1}.webp`)} alt={feature.title} />
                        </FeatureIcon>
                        <TextContainer>
                        <FeatureTitle>{feature.title}</FeatureTitle>
                        <FeatureDescription>
                            <h3>{feature.subtitle1}</h3>
                            <p>{feature.description1}</p>
                            <h3>{feature.subtitle2}</h3>
                            <p>{feature.description2}</p>
                        </FeatureDescription>
                        <LearnMore href="#">자세히 보기</LearnMore>
                        </TextContainer>
                    </FeatureCard>
                ))}
            </FeaturesGrid>
        </Section2>
    );
};

export default SolutionPage;
