import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {getSearch} from "../../apis/dashboard/search";

// 비동기 액션 생성자
export const fetchSearchData = createAsyncThunk(
    'list/fetchSearchData',
    async ({ userId, searchTerm }) => {
        console.log('searchTerm', searchTerm);
        const response = await getSearch(userId, searchTerm); // API 호출
        return response.data;
    }
);

const searchSlice = createSlice({
    name: 'search',
    initialState: {
        search: [],
        status: null,
    },
    reducers: {},
    extraReducers: (builder) => {
       builder
           // fetchSearchData 처리 로직 추가
           .addCase(fetchSearchData.fulfilled, (state, action) => {
               state.searchResults = action.payload; // 검색 결과를 상태에 저장
               state.status = 'succeeded';
           })
           .addCase(fetchSearchData.pending, (state) => {
               state.status = 'loading';
           })
           .addCase(fetchSearchData.rejected, (state, action) => {
               state.status = 'failed';
               state.error = action.error.message;
           });
    },
});

export default searchSlice.reducer;
