import React, { useState } from 'react';
import styled from 'styled-components';

export const NavContainer = styled.nav`
    width: 100%;
    height: 65px;
    background: white;
    border-bottom: 1px #E5E8EB solid;
    z-index: 1000;
`;

export const NavInner = styled.div`
    max-width: 1440px;
    height: 100%;
    margin: 0 auto;
    padding: 0 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

export const LogoSection = styled.div`
    display: flex;
    align-items: center;
`;

export const Logo = styled.img`
    height: 25px;
    cursor: pointer;
`;

export const MenuList = styled.ul`
    display: flex;
    align-items: center;
    list-style: none;
    margin: 0;
    padding: 0;
`;

export const MenuItem = styled.li`
    margin: 0 20px;
    position: relative;

    &:after {
        content: '';
        position: absolute;
        bottom: -4px;
        left: 0;
        width: 0;
        height: 2px;
        background: #30B0C7;
        transition: width 0.3s ease;
    }

    &:hover:after {
        width: ${(props) => (props.disableHover ? '0' : '100%')}; /* Hover 비활성화 조건 */
    }
`;

export const CenterSection = styled.div`
`;

export const MenuLink = styled.a`
    color: ${(props) =>
            props.active ? '#30B0C7' : '#0D141C'};
    text-decoration: none;
    font-size: 14px;
    font-weight: 500;
    line-height: 21px;
    transition: color 0.3s ease;
    align-items: center;
    display: inline-flex;

    &:hover {
        color: ${(props) =>
                props.disableHover ? '#0D141C' : '#30B0C7'}; /* Hover 비활성화 조건 */
    }
`;


export const RightSection = styled.div`
  display: flex;
  align-items: center;
  gap: 36px;
`;

export const Button = styled.button`
    padding: 0 12px;
    border-radius: 6px;
    font-size: 14px;
    font-weight: 500;
    line-height: 21px;
    cursor: pointer;
    transition: all 0.3s ease;
`;

export const LoginButton = styled(Button)`
    //background: transparent;
    //border: 1px solid #EAEAEA;
    //color: #0D141C;
    //width: 76px;
    //height: 30px;
    //&:hover {
    //    background: #F8F9FA;
    //}
    background: transparent;
    border: none;
    padding: 0;
`;

export const SignUpButton = styled(Button)`
    background: #30B0C7;
    border: none;
    color: white;
    width: 76px;
    height: 30px;

    &:hover {
        background: #5AA6AB;
    }
`;

export const ContactButton = styled(Button)`
  background: transparent;
  border: none;
    padding: 0;
`;
