// index/authActions.js
import axios from 'axios';
import {setAccessToken} from "../slices/authSlice";

export const loginUser = (user) => async (dispatch) => {
    const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

    try {
        const response = await axios.post(`${BACKEND_URL}/login`, user, {
            headers: {
                'Accept': 'application/json',
            },
            withCredentials: true,
        });

        const data = response.data;

        if (data.status === 'success') {
            const accessToken = data.content.accessToken;

            // Redux 상태 업데이트
            dispatch(setAccessToken(accessToken));
            return { success: true, message: '로그인 성공!' };
        } else {
            return { success: false, message: data.message || '로그인 실패' };
        }
    } catch (error) {
        console.error('Error during login request:', error);
        return { success: false, message: '서버와의 통신에 실패했습니다.' };
    }
};
