import { createSlice } from "@reduxjs/toolkit";

const navigationSlice = createSlice({
    name: "navigation",
    initialState: {
        activeButton: "home", // 기본값
        isCollapsed: false,     // 사이드바 접힘 상태 추가
        isLocked: false,        // 사이드바 잠금 상태 추가
    },
    reducers: {
        setActiveButton: (state, action) => {
            state.activeButton = action.payload;
        },
        toggleSidebar: (state) => {
            state.isCollapsed = !state.isCollapsed;
        },
        setSidebarLocked: (state) => {
            state.isLocked = !state.isLocked;
        }
    },
});

export const { setActiveButton, toggleSidebar, setSidebarLocked } = navigationSlice.actions;
export default navigationSlice.reducer;
