import styled from "styled-components";

// 맞춤형 시작
export const CustomContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: center;
    padding: 20px 0;
    margin-bottom: 8px;
    gap: 16px;

    h2 {
        font-size: 28px;
        font-weight: 600;
        margin: 0;
    }

    p {
        margin: 0;
        font-size: 20px;
        font-weight: 400;
    }
`;

export const CustomBtnContainer = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    gap: 32px;
`;

export const CustomBtn = styled.button`
    display: flex;
    width: 100%;
    height: 180px;
    flex-direction: column;
    align-items: start;
    justify-content: start;
    color: black;
    padding: 32px;
    cursor: pointer;
    border-radius: 8px;
    background: white;
    border: 1px rgba(222, 226, 230, 0.83) solid;   
    gap: 12px;

    img {
        height: 22px;
    }

    h3 {
        font-size: 18px;
        font-weight: 700;
        line-height: 20px;
        margin: 0;
    }

    span {
        color: #5A5A5A;
        font-size: 16px;
        font-weight: 400;
        line-height: 18px;
    }
`;
