import axios from "axios";

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

export const dummycall = async () => {
    try {
        await axios.post(`${BACKEND_URL}/`,
            {
                headers: {
                    skipInterceptor: true, // 인터셉터 제외
                },
            });
    } catch (error) {
        console.log('dummy call:', error);
    }
}
