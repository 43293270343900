import React from 'react';
import LoginPage from "./pages/login/LoginPage";
import {Route, Routes} from "react-router-dom";
import LandingPage from "./pages/landing/LandingPage";
import SignupPage from "./pages/signup/SignupPage";
import SubtitleGenerationPage from "./pages/results/subtitle_generation/SubtitleGenerationPage";
import CurationPage from "./pages/new_flow/CurationPage";
import FileUploadPage from "./pages/upload/FileUploadPage";
import HomePage from "./pages/home/HomePage";
import NewFlowPage from "./pages/new_flow/NewFlowPage";
import SourceSeparationPage from "./pages/results/source_separation/SourceSeparationPage";
import DashboardPage from "./pages/dashboard/DashboardPage";
import MyProfilePage from "./pages/my_profile/MyProfilePage";
import MyFlowPage from "./pages/my_flow/MyFlowPage";


const App = () => {
    return (
        <Routes>
            <Route path="/" element={<LandingPage/>}/>
            <Route path="/home" element={<HomePage/>}/>
            <Route path="/newflow" element={<NewFlowPage/>}/>
            <Route path="/myflow" element={<MyFlowPage/>}/>
            <Route path="/upload" element={<FileUploadPage/>}/>
            <Route path="/subtitle-generation" element={<SubtitleGenerationPage/>}/>
            <Route path="/source-separation" element={<SourceSeparationPage/>}/>
            <Route path="/login" element={<LoginPage/>}/>
            <Route path="/signup" element={<SignupPage/>}/>
            <Route path="/curation" element={<CurationPage/>}/>
            <Route path="/dashboard" element={<DashboardPage/>}/>
            <Route path="/myprofile" element={<MyProfilePage/>}/>
        </Routes>
    );
};

export default App;

