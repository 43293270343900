import MenuBar from "../../components/menubar/MenuBar";
import Navigation from "../../components/sidebar/Nav";
import React, {useEffect, useState} from "react";
import {Content, MainContainer, MainContent, SubTitle, Title} from "../../styles/styles";
import axiosInstance from "../../apis/utils/axiosConfig";
import {dummycall} from "../../apis/utils/dummy";

const MyProfilePage = () => {
    const options = [
        'IT개발·데이터',
        '미디어 기획·제작',
        '마케팅·홍보',
        '인사·HRD',
        '기획·전략',
        '사무',
        '교육',
        '서비스',
        '영업·고객상담',
        '디자인',
        '연구·R&D',
        '의료',
        '건설·시설',
        '생산·제조',
        '물류·유통',
        '금융·보험',
        '법률·법무',
        '기타',
    ];

    const BACKEND_URL = process.env.REACT_APP_DASHBOARD_URL;

    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [company, setCompany] = useState('');
    const [position, setPosition] = useState('');

    useEffect(() => {
        const fetchUserProfile = async () => {
            try {

                await dummycall();

                const response = await axiosInstance.get(`${BACKEND_URL}/user/profile`);
                const {username, email, organization, jobPosition} = response.data.content;

                setName(username || '');
                setEmail(email || '');
                setCompany(organization || '');
                setPosition(jobPosition || '');
            } catch (error) {
                console.error('Failed to fetch user profile:', error);
            }
        };

        fetchUserProfile();
    }, [BACKEND_URL]);

    const handleUpdateProfile = async (e) => {
        e.preventDefault();
        try {
            await dummycall();

            await axiosInstance.put(`${BACKEND_URL}/user/profile`, {
                "profile": {
                    "username": name,
                    "email": email,
                    "organization": company,
                    "jobPosition": position
                }
            });
            alert('프로필이 업데이트 되었습니다.');
        } catch (error) {
            console.error('Failed to update user profile:', error);
            alert('프로필 업데이트에 실패했습니다.');
        }
    }

    const handleUpdatePassword = async (e) => {
        e.preventDefault();
        try {
            await dummycall();

            await axiosInstance.put(`${BACKEND_URL}/user/password`, {
                    "currentPassword": e.target.newPassword.value,
                    "newPassword": e.target.confirmPassword.value
            });
            alert('비밀번호가 업데이트 되었습니다.');
        } catch (error) {
            console.error('Failed to update password:', error);
            alert('비밀번호 업데이트에 실패했습니다.');
        }
    }

    return (
        <MainContainer>
            <MenuBar/>
            <Content>
                <Navigation/>
                <MainContent>
                    <Title>계정 정보</Title>
                    <form>
                        <label>이름/닉네임</label>
                        <input
                            type="text"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            placeholder="이름을 입력하세요"
                        />
                        <label>이메일</label>
                        <input
                            type="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            placeholder="이메일을 입력하세요"
                        />
                        <label>소속</label>
                        <input
                            type="text"
                            value={company}
                            onChange={(e) => setCompany(e.target.value)}
                            placeholder="소속을 입력하세요"
                        />
                        <label>직무</label>
                        <select
                            value={position}
                            onChange={(e) => setPosition(e.target.value)}
                        >
                            <option value="" disabled>
                                직무를 선택하세요
                            </option>
                            {options.map((item, index) => (
                                <option key={index} value={item}>
                                    {item}
                                </option>
                            ))}
                        </select>
                        <button type="submit" onClick={handleUpdateProfile}>저장</button>
                    </form>
                    <Title>로그인 관리</Title>
                    <SubTitle>비밀번호</SubTitle>
                    <form onSubmit={handleUpdatePassword}>
                        <label>새로운 비밀번호</label>
                        <input
                            type="password"
                            name="newPassword"
                            placeholder="비밀번호를 입력하세요"
                            required
                            minLength="8"
                        />
                        <span>* 영문, 숫자, 특수문자를 포함한 8자 이상</span>

                        <label>비밀번호 확인</label>
                        <input
                            type="password"
                            name="confirmPassword"
                            placeholder="비밀번호를 다시 입력하세요"
                            required
                            minLength="8"
                        />

                        <button type="submit">업데이트</button>
                    </form>
                    <Title>결제·구독 관리</Title>
                    <div>추후 서비스 예정입니다.</div>
                </MainContent>
            </Content>
        </MainContainer>
    );
};

export default MyProfilePage;
