import styled from "styled-components";

export const SpeakerOptionStyles = styled.div`
    margin-top: 30px;
    width: 100%;
    display: flex;
    flex-direction: row;
    text-align: left;
    align-items: center;
    font-size: 14px;
    font-weight: 500;

    .label {
        margin-right: 32px;
    }

    .btn {
        width: 24px;
        height: 24px;
        margin-right: 10px;
        background: #F5F5F5;
        border: none;
        border-radius: 50%;
        color: #6D6D6D;
        justify-content: center;
        align-items: center;
    }

    span {
        color: #6D6D6D;
    }
`;
