import styled from "styled-components";

export const FileUploadContainer = styled.div`
    display: flex;
    width: 100%;
    min-width: 400px;
    max-width: 1076px;
    flex-direction: column;
    margin: 0 24px;

    @media (min-width: 1440px) {
        margin: 0 auto; /* 중앙 정렬 */
        padding: 0 24px;
    }
`;

export const FlowContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    border-radius: 8px;
    border: 1px rgba(222, 226, 230, 0.83) solid;
`;

export const FlowWrapper = styled.div`
    padding: 20px 0;
`;

export const FlowTitle = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    padding: 20px 24px;
    gap: 8px;

    span {
        font-size: 18px;
        font-weight: 700;
        line-height: 30px;
    }

    img {
        width: 16px;
        height: 16px;
    }
`;

export const FlowContent = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 20px 24px;
    gap: 13px;

    span {
        color: #5A5A5A;
        font-size: 14px;
        font-weight: 500;
        line-height: 18px;
    }

    img {
        width: 25px;
    }
`;

// Divider
export const Divider = styled.div`
    width: 100%;
    height: 1px;
    background: rgba(222, 226, 230, 0.83);
`;

export const FlowDataContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 20px 24px;
    gap: 50px;
`;

// 생성날짜
export const FlowData = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 5px;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;

    span {
        color: #5A5A5A;
        font-size: 14px;
        font-weight: 400;
        line-height: 18px;
    }
`;


export const FileUploadContent = styled.div`
    padding: 40px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;

    .form {
        display: flex;
        flex-direction: column;
        width: 100%;
        align-items: center;
    }

    .url {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        margin: 30px 0;
    }

    input {
        width: 100%;
        height: 50px;
        padding: 16px;
        border: 1px #DEE2E6 solid;
        box-sizing: border-box;
        border-radius: 8px;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        background: rgba(255, 255, 255, 0.50);
        overflow: hidden;
        margin-right: 12px;
    }

    .finder {
        display: flex;
        justify-content: center;
        font-size: 12px;
        line-height: 18px;
        gap: 5px;
        margin-bottom: 30px;

        span {
            color: #30B0C7;
            font-weight: 700;
        }
    }
`;

export const FileUploadTitle = styled.div`
    color: #0D141C;
    font-size: 24px;
    font-weight: 700;
    line-height: 40px;
    text-align: left;
    width: 100%;
`;


export const FileButtonStyle = styled.button`
    background-color: #30B0C7;
    color: #F7FAFC;
    height: 50px;
    width: 164px;
    padding: 14px 20px;
    border: none;
    cursor: pointer;
    border-radius: 8px;
    font-weight: 600;
    font-size: 14px;
    font-family: Pretendard, sans-serif;

    &:disabled {
        background-color: #D9D9D9;
        color: white;
        cursor: not-allowed;
    }

    &:hover {
        opacity: 0.8;
    }
`;


export const DropzoneContainer = styled.div`
    border: 1px dashed #E7E7E7;
    border-radius: 10px;
    width: 531px;
    height: 200px;
    padding: 40px 56px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 24px;
`;

export const Message = styled.div`
    button {
        border: none;
        background-color: transparent;
        cursor: pointer;
        color: #30B0C7;
        font-size: 14px;
        font-weight: 500;
        text-decoration: underline;
        padding: 0;
    }

    span {
        color: #5A5A5A;
        font-size: 14px;
        font-weight: 500;
    }
`;
