import React from "react";
import styled from "styled-components";
import custom_1 from '../assets/img/custom_1.svg';
import custom_2 from '../assets/img/custom_2.svg';
import custom_3 from '../assets/img/custom_3.svg';

const Section = styled.section`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 150px 120px;
    width: 100%;
    background: linear-gradient(135deg, #f7fbfc 0%, #eaf4f9 100%);
`;

const Container = styled.div`
    max-width: 1200px;
    width: 100%;
    justify-content: space-between;
    align-items: flex-start;
    display: flex;
    flex-direction: column;
`;

const Content = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
`;

const LeftColumn = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-right: 100px;
`;

const SmallTitle = styled.span`
    display: inline-flex;
    background-color: white;
    justify-content: center;
    align-items: center;
    color: #30B0C7;
    padding: 9px 36px;
    border-radius: 10px;
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 15px;
`;

const MainTitle = styled.h1`
    color: #1a1a1a;
    margin: 0;
    font-size: 48px;
    font-weight: 700;
    line-height: 67px;
    width: 360px;
`;

const RightColumn = styled.div`
    display: flex;
    width: 100%;
    flex-direction: column;
    gap: 32px;
`;

const BenefitItem = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 24px 32px;
    background: rgba(255, 255, 255, 0.8);
    border-radius: 12px;
    border: 1px rgba(48, 176, 199, 0.07) solid;
    width: 100%;
    max-width: 740px;
`;

const IconWrapper = styled.div`
    flex-shrink: 0;
    width: 48px;
    height: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(48, 176, 199, 0.07);
    border-radius: 12px;
    margin-right: 32px;
`;

const Icon = styled.img`
    width: 24px;
    height: 24px;
`;

const BenefitContent = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    
`;
const BenefitTitle = styled.h3`
    color: #30B0C7;
    font-size: 24px;
    font-weight: 600;
    line-height: 34px;
    margin: 0 0 12px;
`;

const BenefitDescription = styled.p`
    font-size: 20px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0;
    margin: 0;
`;


const CustomizationSection = () => {
    return (
        <Section>
            <Container>
            <SmallTitle>Customization</SmallTitle>
            <Content>
                <LeftColumn>
                    <MainTitle>필요한 기능만,<br/> 원하는 대로</MainTitle>
                </LeftColumn>
                <RightColumn>
                    <BenefitItem>
                        <IconWrapper>
                            <Icon src={custom_1} alt="Icon 1"/>
                        </IconWrapper>
                        <BenefitContent>
                            <BenefitTitle>맞춤형 솔루션, 무한 가능성</BenefitTitle>
                            <BenefitDescription>
                                다양한 기능을 조합하여 맞춤형 솔루션을 제공합니다.
                            </BenefitDescription>
                        </BenefitContent>
                    </BenefitItem>
                    <BenefitItem>
                        <IconWrapper>
                            <Icon src={custom_2} alt="Icon 2"/>
                        </IconWrapper>
                        <BenefitContent>
                            <BenefitTitle>기능 구현과 테스트를 한 번에</BenefitTitle>
                            <BenefitDescription>
                                필요한 기능만 선택해 빠르게 구현하고 즉시 결과를 확인하세요.
                            </BenefitDescription>
                        </BenefitContent>
                    </BenefitItem>
                    <BenefitItem>
                        <IconWrapper>
                            <Icon src={custom_3} alt="Icon 3"/>
                        </IconWrapper>
                        <BenefitContent>
                            <BenefitTitle>비즈니스에 맞춘 유연한 설정</BenefitTitle>
                            <BenefitDescription>
                                비즈니스 요구에 따라 자유롭게 조정 가능합니다.
                            </BenefitDescription>
                        </BenefitContent>
                    </BenefitItem>
            </RightColumn>
        </Content>
            </Container>
</Section>
)
    ;
};

export default CustomizationSection;
