import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { hideAlert } from '../../redux/slices/alertSlice';
import { CloseButton, ModalBackground, ModalContent, ModalOverlay } from './AlertModalStyles';

const AlertModal = () => {
    const dispatch = useDispatch();
    const { isVisible, title, message, route } = useSelector((state) => state.alert);

    const [isClosing, setIsClosing] = useState(false);

    useEffect(() => {
        if (!isVisible) return;

        const handleEsc = (event) => {
            if (event.key === 'Escape' || event.key === 'Enter') {
                handleClose();
            }
        };

        window.addEventListener('keydown', handleEsc);
        return () => {
            window.removeEventListener('keydown', handleEsc);
        };
    }, [isVisible]);

    const handleClose = () => {
        setIsClosing(true);
        setTimeout(() => {
            dispatch(hideAlert());
            if (route) {
                window.location.href = route;
            }
        }, 500);
    };

    if (!isVisible) return null; // 알림이 없으면 렌더링하지 않음

    return (
        <ModalBackground>
            <ModalOverlay style={{ pointerEvents: 'auto' }}>
                <ModalContent
                    className={isClosing ? 'close' : ''}
                    onClick={(e) => e.stopPropagation()}
                >
                    <h3>{title}</h3>
                    <p>{message}</p>
                    <CloseButton onClick={handleClose}>Close</CloseButton>
                </ModalContent>
            </ModalOverlay>
        </ModalBackground>
    );
};

export default AlertModal;
