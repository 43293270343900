import React from 'react';
import styled from 'styled-components';

const Container = styled.section`
    width: 100%;
    margin: 0 auto;
    padding: 150px 120px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
`;

const TopLabel = styled.div`
    display: inline-flex;
    background-color: rgba(48, 176, 199, 0.07);
    justify-content: center;
    align-items: center;
    color: #30B0C7;
    padding: 9px 36px;
    border-radius: 10px;
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 15px;
`;

const Title = styled.h2`
    font-size: 48px;
    font-weight: 700;
    line-height: 67px;
    text-align: center;
    margin-bottom: 120px;
`;

const BenefitsCard = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 1200px;
    justify-content: space-between;
    gap: 20px; /* 열 간격 */
    background: linear-gradient(225deg, #30B0C7 0%, rgba(48, 176, 199, 0.42) 100%);
    border-radius: 24px;
    padding: 30px 80px;
    color: #fff;
`;

const Column = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between; /* 아이템 사이 간격 균등 */
    gap: 20px; /* 아이템 간의 간격 */
`;

const BenefitItem = styled.div`
    display: flex;
    flex-direction: column;
    padding: 48px 20px;
    flex: 1; /* 동일한 너비 */
    text-align: center;
`;

const BenefitTitle = styled.h3`
    margin-bottom: 8px;
    text-align: center;
    font-size: 30px;
    font-weight: 700;
    line-height: 34px;
    letter-spacing: 1px;
`;

const BenefitDescription = styled.p`
    font-size: 20px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0px;
`;

const KeyBenefits = () => {
    const benefits = [
        {
            title: "단순 음성 변환 그 이상",
            description: "음성을 이해하고 활용할 수 있는 AI",
        },
        {
            title: "높은 성능으로 편리하게",
            description: "정확하고 안정적인 기술",
        },
        {
            title: "간단한 도입",
            description: "코드 한 줄로 시작",
        },
        {
            title: "빠르고 끊김 없는 처리",
            description: "실시간으로 고객 경험을 향상",
        },
        {
            title: "원하는 대로 커스터마이징",
            description: "클릭 몇 번으로 기능을 설정하고 결과를 확인",
        },
    ];

    // 1열과 2열 데이터 나누기
    const half = Math.ceil(benefits.length / 2);
    const column1 = benefits.slice(0, half);
    const column2 = benefits.slice(half);

    return (
        <Container>
            <TopLabel>Key Benefits</TopLabel>
            <Title>오디온이 특별한 이유</Title>
            <BenefitsCard>
                {/* 첫 번째 카드 */}
                    <Column>
                            {column1.map((benefit, index) => (
                                <BenefitItem key={index}>
                                    <BenefitTitle>{benefit.title}</BenefitTitle>
                                    <BenefitDescription>{benefit.description}</BenefitDescription>
                                </BenefitItem>
                            ))}
                    </Column>
                    <Column>
                        {column2.map((benefit, index) => (
                            <BenefitItem key={index}>
                                <BenefitTitle>{benefit.title}</BenefitTitle>
                                <BenefitDescription>{benefit.description}</BenefitDescription>
                            </BenefitItem>
                        ))}
                </Column>
        </BenefitsCard>
</Container>
)
    ;
};

export default KeyBenefits;
