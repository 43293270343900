// subtitle 데이터를 파싱하는 함수들을 정의한 파일
export const parseFullText = (output) => {
    const result = {};
    let subtitle = [];
    const s2t = output.speech2text;

    console.log('s2tlength:', s2t.utterances.length);

    // speakerNumber를 speakerLetter로 변환
    for (let i = 0; i < s2t.utterances.length; i++) {
            console.log(`s2t[${i}].speaker:`, s2t.utterances[i].speaker);
        const speakerNumber = s2t.utterances[i].speaker.match(/SPEAKER-(\d+)/)[1];
        // 숫자를 알파벳으로 변환하는 로직 ('00' -> 'A', '01' -> 'B' ...)
        const speakerLetter = String.fromCharCode(65 + parseInt(speakerNumber, 10));

        console.log('text:', s2t.utterances[i].text);
        // result에 subtitle 정보를 저장
        subtitle.push({
            start: s2t.utterances[i].start,
            end: s2t.utterances[i].end,
            text: s2t.utterances[i].text,
            speaker: speakerLetter,
            path: s2t.utterances[i].filename,
            segment: i,
            score: s2t.utterances[i].score,
            emotions: s2t.utterances[i].emotions
        });
    }

    // result 객체에 subtitle 정보를 저장
    result.subtitle = subtitle;
    result.vtt = output.s2t_postproc.vtt;
    result.srt = output.s2t_postproc.srt;
    result.youtubeInfo = output.youtubeInfo;
    result.signal_processing = output.signal_processing;
    result.text = s2t.text;
    result.summary = output.summary.content.text;

    console.log('parseFullText result:', subtitle);

    return result;
};
