import React, {useEffect} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {fetchProjects} from '../../redux/slices/projectsSlice';
import {useNavigate} from 'react-router-dom';
import Navigation from "../../components/sidebar/Nav";
import {Content, MainContainer, MainContent, Title} from "../../styles/styles";
import MenuBar from "../../components/menubar/MenuBar";
import {ProjectContainer} from "./HomePageStyles";
import {FileBox, MediaBox, VolumeContainer} from "../dashboard/DashboardStyles";
import folder from "../../assets/dashboard/folder_icon.svg";
import {MdMovieEdit} from "react-icons/md";
import {fetchListData, fetchVolumeData} from "../../redux/slices/listSlice";

const HomePage = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    // Redux에서 프로젝트 상태를 가져옴
    const projects = useSelector((state) => state.projects.items);
    const status = useSelector((state) => state.projects.status);

    // 컴포넌트 마운트 시 API 호출
    useEffect(() => {
        console.log('fetchProjects');
        dispatch(fetchProjects());
        console.log('projects', projects);

    }, [dispatch]);

    const { list, page, count, volume} = useSelector((state) => state.list);

    useEffect(() => {
        dispatch(fetchListData());
        dispatch(fetchVolumeData());
    }, [dispatch]);

    const {totalCount, totalDuration, video, audio} = volume;

    return (
        <MainContainer>
            <MenuBar/>
            <Content>
                <Navigation/>
                <MainContent style={{gap: "32px"}}>
                    <div>
                        <Title>홈</Title>
                        <VolumeContainer>
                            <FileBox>
                                <div className='icon'>
                                    <img src={folder} alt={folder}/>
                                </div>
                                <div className='content'>
                                    <div className='item'>
                                        <div className='title'>전체 파일</div>
                                        <div>{totalCount}개</div>
                                    </div>
                                    <div className='item'>
                                        <div className='title'>전체 파일 크기</div>
                                        <div>{totalDuration}</div>
                                    </div>
                                </div>
                            </FileBox>
                            <MediaBox>
                                <div className='icon'>
                                    <MdMovieEdit color='white'/>
                                </div>
                                <div className='content'>
                                    <div className='item'>
                                        <div className='title'>비디오</div>
                                        <div>{video}</div>
                                    </div>
                                    <div className='item'>
                                        <div className='title'>오디오</div>
                                        <div>{audio}</div>
                                    </div>
                                </div>
                            </MediaBox>
                        </VolumeContainer>
                    </div>
                    <div>
                        <Title>사용량</Title>
                        <div>
                            추후 서비스 예정입니다.
                        </div>
                    </div>
                    <div>
                        <Title>나의 플로우</Title>
                        {status === 'loading' && <p>로딩 중...</p>}
                        {projects.map((project, index) => (
                            <ProjectContainer key={index}>
                                <div>프로젝트 이름: {project.name}</div>
                                {project.components.map((component, index) => (
                                    <div key={index}>컴포넌트 이름: {component}</div>
                                ))}
                                <div>생성일시: {project.createdAt}</div>
                                <div>작업한 파일 수: {project.numDocuments}</div>
                            </ProjectContainer>
                        ))}
                    </div>
                    <div>
                        <Title>최근 파일</Title>
                        <div>
                            {list.map((item, index) => (
                                <div>
                                <img key={index} src={item.thumbnail} alt={item.thumbnail} style={{width: "271px", height: "153px"}}/>
                                <div key={index}>{item.uploadedFileName}</div>
                                    <div key={index}>{item.createdAt}</div>
                                </div>
                            ))}
                        </div>
                    </div>
                </MainContent>
            </Content>
        </MainContainer>
    );
};

export default HomePage;
