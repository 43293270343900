import React from 'react';
import styled from 'styled-components';
import cognition_graph from '../assets/img/cognition_graph.webp';
import cognition_1 from '../assets/img/cognition_1.svg';
import cognition_2 from '../assets/img/cognition_2.svg';
import cognition_3 from '../assets/img/cognition_3.svg';

const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 150px 120px;
    width: 100%;
    background-color: rgba(48, 176, 199, 0.07);
`;

const SmallTitle = styled.p`
    display: inline-flex;
    background-color: white;
    color: #30B0C7;
    padding: 9px 36px;
    border-radius: 10px;
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 15px;
`;

const MainContent = styled.div`
    max-width: 1158px;
    width: 100%;
    justify-content: space-between;
    align-items: flex-start;
    display: flex;
    flex-direction: column;
`;

const Header = styled.div`
  display: flex;
    width: 100%;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 120px;
`;

const TitleSection = styled.div`
    max-width: 628px;
`;

const Title = styled.h1`
    font-size: 48px;
    font-weight: 700;
    margin-bottom: 40px;
    line-height: 67px;
`;

const Subtitle = styled.p`
  font-size: 20px;
    font-weight: 600;
  line-height: 28px;
`;

const ImageSection = styled.div`
    margin-left: 63px;
    
    img {
        max-width: 467px;
    }
`;

const FeaturesGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
`;

const FeatureCard = styled.div`
  padding: 32px;
  border-radius: 12px;
  background: #fff;
`;

const FeatureIcon = styled.div`
  height: 32px;
  margin-bottom: 16px;
  color: #30B0C7;
`;

const FeatureTitle = styled.h3`
  font-size: 20px;
  font-weight: 700;
`;

const FeatureDescription = styled.p`
    opacity: 0.80;
    color: black;
    font-size: 16px;
    font-weight: 600;
  line-height: 26px;
  margin-bottom: 16px;
`;

const FeatureLinks = styled.div`
  p {
    color: #30B0C7;
    font-size: 14px;
      font-weight: 400;
      line-height: 20px;
  }
`;

const VoiceCognitionPage = () => {
    return (
        <Container>
            <MainContent>
                <Header>
                    <TitleSection>
                        <SmallTitle>Voice Cognition</SmallTitle>
                        <Title>
                            VOC:Voice Cognition<br />
                            음성 데이터에서 인사이트로
                        </Title>
                        <Subtitle>
                            VOC는 음성 데이터를 분석하고 그 속에서 중요한 의미를 추출합니다.<br/>
                            음성을 단순히 인식하는 수준을 넘어서, 고객의 요구에 맞춘<br/>
                            실용적인 인사이트를 제공합니다.
                        </Subtitle>
                    </TitleSection>
                    <ImageSection>
                        <img
                            src={cognition_graph}
                            alt="Voice Cognition Graph"
                        />
                    </ImageSection>
                </Header>
                <FeaturesGrid>
                    <FeatureCard>
                        <FeatureIcon>
                            <img src={cognition_1} alt="Voice Cognition 1" />
                        </FeatureIcon>
                        <FeatureTitle>감정 분석</FeatureTitle>
                        <FeatureDescription>
                            감정 변화를 분석하여 음성 데이터를 더 깊이 이해합니다.
                        </FeatureDescription>
                        <FeatureLinks>
                            <p>활용 사례: 고객 상담센터에서 실시간 감정 분석, 맞춤형 서비스 제공.</p>
                        </FeatureLinks>
                    </FeatureCard>
                    <FeatureCard>
                        <FeatureIcon>
                            <img src={cognition_2} alt="Voice Cognition 2" />
                        </FeatureIcon>
                        <FeatureTitle>질병 예측</FeatureTitle>
                        <FeatureDescription>
                            음성 데이터로 건강 상태를 예측하고 경고 신호를 제공합니다.
                        </FeatureDescription>
                        <FeatureLinks>
                            <p>활용 사례: 질병 징후 모니터링, 정기적인 건강 관리.</p>

                        </FeatureLinks>
                    </FeatureCard>
                    <FeatureCard>
                        <FeatureIcon>
                            <img src={cognition_3} alt="Voice Cognition 3" />
                        </FeatureIcon>
                        <FeatureTitle>언어 학습</FeatureTitle>
                        <FeatureDescription>
                            학습자의 음성을 분석해 맞춤형 솔루션을 제공합니다.
                        </FeatureDescription>
                        <FeatureLinks>
                            <p>활용 사례: 발음 교정, 언어 학습 진단.</p>
                        </FeatureLinks>
                    </FeatureCard>
                </FeaturesGrid>
            </MainContent>
        </Container>
    );
};

export default VoiceCognitionPage;
