import styled, { keyframes } from 'styled-components';
import React from "react";
import logo from "../assets/signup/signup_logo.svg";

const pulse = keyframes`
    0%, 100% {
        transform: scale(1); /* 기본 크기 */
    }
    50% {
        transform: scale(1.1); /* 크기 확대 */
    }
`;

const Wrapper = styled.div`
    width: 100%;
    height: 757px;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
`;

const Circle = styled.div`
    position: absolute;
    background: rgba(48, 176, 199, 0.04);
    border-radius: 9999px;
    animation: ${pulse} 6s infinite; /* 애니메이션 적용 */
`;

const OuterCircle = styled(Circle)`
    width: 1160px;
    height: 1159px;
    top: 0;
    left: 0;
    animation-delay: 0s; /* 첫 번째 원은 즉시 시작 */
`;

const MiddleCircle = styled(Circle)`
    width: 920px;
    height: 919px;
    top: 120px;
    left: 120px;
    animation-delay: 0.5s; /* 두 번째 원은 0.5초 후 시작 */
`;

const InnerCircle = styled(Circle)`
    width: 680px;
    height: 679px;
    top: 240px;
    left: 240px;
    animation-delay: 1s; /* 세 번째 원은 1초 후 시작 */
`;

const SmallCircle = styled(Circle)`
    width: 440px;
    height: 439px;
    top: 360px;
    left: 360px;
    animation-delay: 1.5s; /* 네 번째 원은 1.5초 후 시작 */
`;

const SmallestCircle = styled(Circle)`
    width: 200px;
    height: 199px;
    top: 480px;
    left: 480px;
    animation-delay: 2s; /* 다섯 번째 원은 2초 후 시작 */
`;

const IconTextButtonWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: absolute;
    z-index: 1;
    gap: 40px;
    width: 100%;
    height: 100%;
`;

const Icon = styled.img`
    width: 60px;
`;

const Text = styled.p`
    text-align: center;
    color: #28170B;
    font-size: 56px;
    font-weight: 800;
    line-height: 78px;
    margin-bottom: 20px;
`;

const Description = styled.p`
    color: black;
    font-size: 20px;
    font-weight: 500;
    line-height: 27px;
`;

const Button = styled.button`
    width: 319px;
    height: 52px;
    padding: 12px 24px;
    background: #30B0C7;
    border-radius: 360px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    display: inline-flex;
    border: none;
    color: white;
    font-size: 20px;
    font-weight: 600;
    line-height: 27px;

    &:hover {
        background-color: #268f9f;
    }
`;

const BackgroundCircles = () => {
    return (
        <Wrapper>
            <div style={{position: 'relative', width: '1160px', height: '1159px'}}>
                <OuterCircle />
                <MiddleCircle />
                <InnerCircle />
                <SmallCircle />
                <SmallestCircle />
            </div>

            <IconTextButtonWrapper>
                <Icon src={logo} alt="logo"/>
                <Text>
                    오디온,<br/>코드 한줄로 도입하는 음성 AI
                </Text>
                <Description>
                    복잡한 음성 AI, 이제 오디온으로 더 간단하게.
                </Description>
                <Button>무료 체험하기</Button>
            </IconTextButtonWrapper>
        </Wrapper>
    );
};

export default BackgroundCircles;
